import {
  CREATE_WARNING,
  DELETE_WARNING,
  GET_WARNING,
  LIST_WARNINGS_ADMIN_PAGINATED,
  UPDATE_WARNING
} from './graphql/queries';
import { useQuery } from '../useQuery';

/** @format */
export const useSettings = () => {
  const { getDataGQL: getWarning } = useQuery(GET_WARNING);
  const { getDataGQL: listWarningsAdminPaginated } = useQuery(LIST_WARNINGS_ADMIN_PAGINATED);
  const { getDataGQL: createWarning } = useQuery(CREATE_WARNING);
  const { getDataGQL: updateWarning } = useQuery(UPDATE_WARNING);
  const { getDataGQL: deleteWarning } = useQuery(DELETE_WARNING);



  const onGetWarning = async (data) => {
    const response = await getWarning({
      variables: data
    });
    if (response?.adminRoute?.getWarning) {
      return response?.adminRoute?.getWarning;
    }
    return null;
  }

  const onListWarningsAdminPaginated = async (data) => {
    const response = await listWarningsAdminPaginated({
      variables: data
    });
    if (response?.adminRoute?.listWarningsAdminPaginated) {
      return response?.adminRoute?.listWarningsAdminPaginated;
    }
    return null;
  };

  const onCreateWarning = async (data) => {
    const response = await createWarning({
      variables: data
    });
    if (response?.adminRoute?.createWarning) {
      return response?.adminRoute?.createWarning;
    }
    return null;
  }

  const onUpdateWarning = async (data) => {
    const response = await updateWarning({
      variables: data
    });
    if (response?.adminRoute?.updateWarning) {
      return response?.adminRoute?.updateWarning;
    }
    return null;
  }

  const onDeleteWarning = async (data) => {
    const response = await deleteWarning({
      variables: data
    });
    if (response?.adminRoute?.deleteWarning) {
      return response?.adminRoute?.deleteWarning;
    }
    return null;
  }


  return {
    onGetWarning,
    onListWarningsAdminPaginated,
    onCreateWarning,
    onUpdateWarning,
    onDeleteWarning
  };
};
