import React, { useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';
import './custom-products.css';
import { IconEdit, IconPlus } from '@tabler/icons-react';
import ModalEdit from './modalEdit';
import { useShop } from '../../../hooks/Shop/useShop';
import UITable from '../../utilities/Table';
import { UIPageHeader } from '../../utilities/Headers';

const ShopProductsView = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);

  const { onListShopProductsAdminPaginated } = useShop();

  const handleModalClose = useCallback(() => {
    setEditMode(false);
    setForceUpdate(true);
  }, []);


  const prepareValues = (products) =>
    products?.map((product) => {
      return {
        product: product,
        nameEl: (
          <Box>
            <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
              {product?.name}
            </Typography>
          </Box>
        ),
        actionsEl: (
          <Box
            onClick={() => {
              setSelectedProduct(product);
              setEditMode(true);
            }}
          >
            <IconEdit cursor={'pointer'} />
          </Box>
        )
      };
    });

  return (
    <Box className="products-view" w={'100%'} h={'100%'} overflow={'auto'}>
      <Box
        position={'fixed'}
        display={'flex'}
        right={10}
        bottom={10}
        bgcolor={'#2c3e50'}
        p={'10px'}
        borderRadius={'100%'}
        w={'50px'}
        h={'50px'}
        color={'white'}
        justifyContent={'center'}
        alignItems={'center'}
        cursor={'pointer'}
        onClick={() => {
          setSelectedProduct(null);
          setEditMode(true);
        }}
      >
        <IconPlus stroke={3} size="2rem" />
      </Box>

      <ModalEdit modalOpen={editMode} handleModalClose={handleModalClose} product={selectedProduct} />

      <UIPageHeader
        title={'Prodotti'}
        subtitle={
          'Gestisci qui i tuoi prodotti. In questa lista appariranno tutti i prodotti che hai creato. Clicca sull\'icona dell\'editor per modificarli.'
        }
      />

      <UITable
        columns={[
          {
            name: 'Nome',
            value: 'nameEl',
            size: 400
          },
          {
            name: 'Azioni',
            value: 'actionsEl',
            size: 100
          }
        ]}
        prepareValues={prepareValues}
        enableSearch={true}
        enableCustomFilters={true}
        infiniteScrollEndMessage={'Non ci sono altri prodotti da mostrare'}
        infiniteScrollFunction={onListShopProductsAdminPaginated}
        tableHeight={'calc(100vh - 400px)'}
        forceUpdate={forceUpdate}
        setForceUpdate={setForceUpdate}
      />
    </Box>
  );
};
export default ShopProductsView;
