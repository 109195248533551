import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { IconCheck, IconTrash } from '@tabler/icons-react';
import { DatePicker, PickersDay, TimePicker } from '@mui/x-date-pickers';
import colors from './../calendar/colors';

import { registerLocale } from 'react-datepicker';
import it from 'date-fns/locale/it';
import { useCalendar } from '../../../hooks/Calendar/useCalendar';
import { useTreatments } from '../../../hooks/Treatments/useTreatments';
import * as Yup from 'yup';
import { Formik } from 'formik';
import moment from 'moment';
import { useAccounts } from '../../../hooks/Accounts/useAccounts';
import { useAuthentication } from '../../../utils/useAuthentication';
import { useOffices } from '../../../hooks/Offices/useOffices';
import { UIModal, UIModalBody, UIModalButtons, UiModalInternal } from '../../utilities/Modal';
import { UIPageHeader } from '../../utilities/Headers';
import { UIFormControlBox } from '../../utilities/Form';
import { UICheckbox, UIInput, UITextarea } from '../../utilities/Input';
import UITypography from '../../utilities/Typography';
import { UIButton } from '../../utilities/Button';
import UIInputAutocomplete, { UIInputSelect } from '../../utilities/InputSelect';
import { UiDialog } from '../../utilities/Dialog';
import { toast } from 'react-toastify';
import ModalAddPatient from './modalAddPatient';

registerLocale('it', it);

const ModalAppointmentEdit = ({ modalOpen, appointment, handleModalClose, handleModaDetailsClose }) => {
  const data = useAuthentication();
  const user = data?.user?.account_data;
  let [appointmentData, setAppointmentData] = useState(null);
  let [calendars, setCalendars] = useState([]);
  let [treatments, setTreatments] = useState([]);
  let [patients, setPatients] = useState([]);
  let [aliasActive, setAliasActive] = useState(appointmentData && !appointmentData?.patient_id);
  let [deleteMode, setDeleteMode] = useState(false);
  let [addPatientMode, setAddPatientMode] = useState(false);
  let [pageLoading, setPageLoading] = useState(true);
  let [operationLoading, setOperationLoading] = useState(false);
  let [hours, setHours] = useState([]);
  let [offices, setOffices] = useState([]);
  const [startDateHours] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDateHours] = useState(moment().add(2, 'year').startOf('month').format('YYYY-MM-DD'));
  const [filters, setFilters] = useState({
    query: ''
  });

  const { onListCalendarsAdmin, onUpdateAppointment, onCreateAppointment, onDeleteAppointment, onGetAppointment, onListDoctorHours } =
    useCalendar();
  const { onListTreatmentsAdmin, onListTreatmentsDoctor } = useTreatments();
  const { onListOffices } = useOffices();
  const { onListUsersAdmin } = useAccounts();

  const getAppointment = async () => {
    if (!appointment?.id) return;
    let res = await onGetAppointment({
      appointment_id: appointment?.id
    });
    setAppointmentData(res);
    setFilters({
      ...filters,
      query: res?.patient_alias ? '' : `${res?.patient?.name} ${res?.patient?.surname}`
    });
  };

  useEffect(() => {
    setAliasActive(appointmentData && !appointmentData?.patient_id);
  }, [appointmentData]);

  const listPatients = async () => {
    if (!filters?.query || filters?.query.length < 3) return;
    let res = await onListUsersAdmin({ filters });
    setPatients(res);
  };

  const listCalendars = async () => {
    let res = await onListCalendarsAdmin({
      doctor_id: null
    });
    setCalendars(res);
  };

  const listTreatments = async () => {
    if (user?.admin || user?.clinic) {
      let res = await onListTreatmentsAdmin();
      setTreatments(res);
    } else {
      let res = await onListTreatmentsDoctor();
      setTreatments(res);
    }
  };

  const listOffices = async () => {
    let res = await onListOffices();
    setOffices(res);
  };

  const listDoctorHours = async () => {
    let res = await onListDoctorHours({
      start: startDateHours,
      end: endDateHours
    });

    setHours(res);
  };

  useEffect(() => {
    if (!modalOpen) return;
    getAppointment().then(() => {
      listCalendars().then(() => {
        listTreatments().then(() => {
          listOffices().then(() => {
            listDoctorHours().then(() => {
              if (!appointment?.id) {
                listPatients().then(() => {
                  setPageLoading(false);
                });
              } else {
                setPageLoading(false);
              }
            });
          });
        });
      });
    });
  }, [modalOpen]);

  useEffect(() => {
    if (!modalOpen) return;
    listPatients().then(() => {});
  }, [filters]);

  const calcDuration = (start, end) => {
    if (!moment(start).isValid() || !moment(end).isValid()) return undefined;
    return moment(end).diff(moment(start), 'minute');
  };

  const handleSubmit = (values) => {
    if (!pageLoading) {
      setOperationLoading(true);
      if (appointmentData?.id) {
        let time = moment(values?.time, 'HH:mm');
        let date = moment(values?.date).utc(true).startOf('day').add(time.hours(), 'hour').add(time.minutes(), 'minute');

        onUpdateAppointment({
          appointment_id: appointmentData.id,
          appointment_data: {
            patient_id: values?.patient,
            patient_alias: values?.alias,
            patient_phone: values?.patient_phone,
            patient_email: values?.patient_email,
            alias_active: aliasActive,
            calendar_id: values?.calendar,
            start_date: date.toDate(),
            end_date: date.add(values?.duration, 'minute').toDate(),
            treatment_id: values?.treatment,
            note: values?.note
          }
        }).then((res) => {
          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: res?.responseStatus ? '#00e676' : 'red',
              color: '#ffffff'
            }
          });

          if (res?.response) {
            handleModalCloseInternal();
          }
          setOperationLoading(false);
        });
      } else {
        let time = moment(values?.time, 'HH:mm');
        let date = moment(values?.date).utc(true).startOf('day').add(time.hours(), 'hour').add(time.minutes(), 'minute');

        onCreateAppointment({
          appointment_data: {
            patient_id: values?.patient,
            patient_alias: values?.alias,
            patient_phone: values?.patient_phone,
            patient_email: values?.patient_email,
            alias_active: aliasActive,
            calendar_id: values?.calendar,
            start_date: date.toDate(),
            end_date: date.add(values?.duration, 'minute').toDate(),
            treatment_id: values?.treatment,
            note: values?.note
          }
        }).then((res) => {
          if (res?.response) {
            handleModalCloseInternal();
          }
          setOperationLoading(false);
        });
      }
    }
  };

  const handleDeleteAppointment = () => {
    setDeleteMode(false);
    setOperationLoading(true);
    onDeleteAppointment({
      appointment_id: appointmentData.id
    }).then(() => {
      setOperationLoading(false);
      setAppointmentData(null);
      handleModaDetailsClose(false);
    });
  };

  const handleModalCloseInternal = () => {
    setAppointmentData(null);
    setPatients([]);
    setFilters({
      query: ''
    })
    setAliasActive(false);
    setDeleteMode(false);
    setPageLoading(true);
    setOperationLoading(false);
    setOffices([]);
    setTreatments([]);
    setCalendars([]);
    setHours([]);
    handleModalClose();
  }

  return (
    <UIModal open={modalOpen} onClose={handleModalCloseInternal} allowFullScreen={true} className={'modal'}>
      <UiDialog
        type={'default'}
        variant={'cancel'}
        title={'Vuoi eliminare definitivamente l’appuntamento? Al paziente non appariranno notifiche'}
        open={deleteMode}
        onClose={() => setDeleteMode(false)}
        onConfirm={handleDeleteAppointment}
      />

      <ModalAddPatient modalOpen={addPatientMode} handleModalClose={() => setAddPatientMode(false)}/>

      <UiModalInternal onClose={handleModalCloseInternal} isLoading={pageLoading} isOperationLoading={operationLoading}>
        <Formik
          enableReinitialize={false}
          initialValues={{
            patient: Number(appointmentData?.patient_id) ?? Number(appointment?.patient_id),
            alias: appointmentData?.patient_alias ?? '',
            patient_phone: appointmentData?.patient_phone ?? '',
            patient_email: appointmentData?.patient_email ?? '',
            calendar: Number(appointmentData?.calendar_id) ?? '',
            date: appointmentData ? moment(appointmentData?.date) : moment(),
            time: appointmentData ? moment(appointmentData?.start_hour, 'HH:mm') : moment('06:30', 'HH:mm'),
            treatment: Number(appointmentData?.treatment_id) ?? '',
            duration: calcDuration(
              appointmentData?.date + ' ' + appointmentData?.start_hour,
              appointmentData?.date + ' ' + appointmentData?.end_hour
            ),
            note: appointmentData?.note ?? ''
          }}
          validationSchema={Yup.object().shape({
            patient: Yup.mixed()
              .nullable()
              .test('patient', 'Paziente richiesto', function (value) {
                if (aliasActive) {
                  return true;
                }

                return value !== undefined && value !== null && value !== '';
              }),
            alias: Yup.string()
              .nullable()
              .when('patient', {
                is: (val) => val === null || val === undefined || val === '',
                then: Yup.string().required('Alias richiesto')
              }),
            patient_phone: Yup.string().nullable(),
            patient_email: Yup.string().nullable(),
            calendar: Yup.number().nullable().required('Calendario richiesto'),
            date: Yup.string().nullable().required('Data richiesta'),
            time: Yup.string().nullable().required('Ora richiesta'),
            treatment: Yup.number().nullable().required('Trattamento richiesto'),
            duration: Yup.number().nullable().required('Durata richiesta'),
            note: Yup.string().nullable()
          })}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ errors, handleBlur, handleSubmit, touched, values, submitForm, setFieldValue }) => (
            <form onSubmit={handleSubmit} id={'appointment-form'}>
              <UIModalBody
                sx={{
                  bgcolor: 'transparent'
                }}
              >
                <UIPageHeader
                  title={appointment ? 'Modifica appuntamento' : 'Aggiungi appuntamento'}
                  sx={{
                    textAlign: 'center'
                  }}
                />
                <UIFormControlBox error_message={errors?.patient} label={'Paziente'} error={Boolean(touched.patient && errors.patient)}>
                  <UIInputAutocomplete
                    value={Number(values?.patient)}
                    setValue={(value) => setFieldValue('patient', value?.id)}
                    options={patients?.map((patient) => {
                      return {
                        id: Number(patient?.id),
                        label: `${patient?.name} ${patient?.surname} - ${patient?.email} - ${patient?.phone}`
                      };
                    })}
                    disabled={aliasActive}
                    needTimeoutText={false}
                    handleBlur={handleBlur}
                    onTextValueChange={(value) => {
                      if (!value) return;
                      if (value.length < 3) return;
                      setFilters({
                        ...filters,
                        query: !value ? '' : value
                      });
                    }}
                  />
                  {!aliasActive && values?.patient && (
                    <Box m={10} textAlign={'center'}>
                      <UITypography
                        variant={'Oxygen700'}
                        size={'13px'}
                        title={`${patients?.find((patient) => Number(patient?.id) === Number(values?.patient))?.phone_country?.dial_code} ${
                          patients?.find((patient) => Number(patient?.id) === Number(values?.patient))?.phone
                        }`}
                      />
                      <UITypography
                        variant={'Oxygen700'}
                        size={'13px'}
                        title={patients?.find((patient) => Number(patient?.id) === Number(values?.patient))?.email}
                      />
                    </Box>
                  )}
                </UIFormControlBox>
                <UIFormControlBox>
                  <UIButton
                    type={'button'}
                    variant={'phone'}
                    label={'Aggiungi nuovo paziente alla lista'}
                    sxCustom={{
                      width: '100%',
                      borderRadius: '8px',
                      border: '1px solid #D0D5DD',
                      textTransform: 'none',
                    }}
                    disabled={aliasActive}
                    onClick={() => setAddPatientMode(true)}
                  />
                </UIFormControlBox>

                <UIFormControlBox sx={{}}>
                  <UICheckbox
                    name={'aliasActive'}
                    label={'Usa paziente personalizzato'}
                    checked={aliasActive}
                    onChange={(event) => setAliasActive(event.target.checked)}
                  />
                </UIFormControlBox>

                {aliasActive && (
                  <>
                    <UIFormControlBox label={'Alias'} error={Boolean(touched.alias && errors.alias)}>
                      <UIInput value={values.alias} onChange={() => setFieldValue('alias', event.target.value)} disabled={!aliasActive} />
                    </UIFormControlBox>
                    <UIFormControlBox label={'Alias Cellulare'} error={Boolean(touched.patient_phone && errors.patient_phone)}>
                      <UIInput
                        value={values.patient_phone}
                        onChange={() => setFieldValue('patient_phone', event.target.value)}
                        disabled={!aliasActive}
                      />
                    </UIFormControlBox>
                    <UIFormControlBox label={'Alias Email'} error={Boolean(touched.patient_email && errors.patient_email)}>
                      <UIInput
                        value={values.patient_email}
                        onChange={() => setFieldValue('patient_email', event.target.value)}
                        disabled={!aliasActive}
                      />
                    </UIFormControlBox>
                  </>
                )}

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    px: '20px'
                  }}
                >
                  <UIFormControlBox
                    label={'Data'}
                    fullWidth
                    error={Boolean(touched.date && errors.date)}
                    sx={{
                      p: 0
                    }}
                  >
                    <DatePicker
                      value={values?.date}
                      dateFormat="dd/MM/yyyy"
                      onChange={(res) => {
                        setFieldValue('date', res);
                      }}
                      slots={{
                        day: CustomDayPicker
                      }}
                      slotProps={{
                        day: { hours, offices }
                      }}
                      locale={'it'}
                      style={{ width: '100%', border: '1px solid red' }}
                      onBlur={handleBlur}
                    />
                  </UIFormControlBox>
                  <UIFormControlBox
                    label={'Ora'}
                    fullWidth
                    error={Boolean(touched.time && errors.time)}
                    sx={{
                      p: 0
                    }}
                  >
                    <TimePicker
                      value={values?.time}
                      format={'HH:mm'}
                      onChange={(res) => {
                        setFieldValue('time', res);
                      }}
                      ampm={false}
                      minutesStep={5}
                      minTime={moment('06:30', 'HH:mm')}
                      maxTime={moment('23:30', 'HH:mm')}
                      onBlur={handleBlur}
                    />
                  </UIFormControlBox>
                </Box>

                <UIFormControlBox label={'Agenda'} error={Boolean(touched.calendar && errors.calendar)}>
                  <UIInputAutocomplete
                    value={values?.calendar}
                    setValue={(value) => setFieldValue('calendar', value?.id)}
                    options={calendars?.map((calendar) => {
                      return {
                        id: Number(calendar?.id),
                        label: `${calendar?.doctor?.name} ${calendar?.doctor?.surname} - ${calendar?.office?.name} - ${calendar?.office?.city?.name}`
                      };
                    })}
                    needTimeout={false}
                  />
                </UIFormControlBox>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    px: '20px'
                  }}
                >
                  <UIFormControlBox
                    label={'Trattamento'}
                    error={Boolean(touched.treatment && errors.treatment)}
                    sx={{
                      p: 0
                    }}
                  >
                    <UIInputAutocomplete
                      value={values?.treatment}
                      setValue={(value) => setFieldValue('treatment', value?.id)}
                      options={treatments?.map((treatment) => {
                        return {
                          id: Number(treatment?.id),
                          label: treatment?.name
                        };
                      })}
                      needTimeout={false}
                    />
                  </UIFormControlBox>
                  <UIFormControlBox
                    label={'Durata'}
                    error={Boolean(touched.duration && errors.duration)}
                    sx={{
                      p: 0
                    }}
                  >
                    <UIInputSelect
                      fullWidth={true}
                      value={values?.duration}
                      onChange={(e) => setFieldValue('duration', e.target.value)}
                      options={[
                        {
                          value: 5,
                          label: '5 min'
                        },
                        {
                          value: 10,
                          label: '10 min'
                        },
                        {
                          value: 15,
                          label: '15 min'
                        },
                        {
                          value: 20,
                          label: '20 min'
                        },
                        {
                          value: 25,
                          label: '25 min'
                        },
                        {
                          value: 30,
                          label: '30 min'
                        },
                        {
                          value: 35,
                          label: '35 min'
                        },
                        {
                          value: 40,
                          label: '40 min'
                        },
                        {
                          value: 45,
                          label: '45 min'
                        },
                        {
                          value: 50,
                          label: '50 min'
                        },
                        {
                          value: 55,
                          label: '55 min'
                        },
                        {
                          value: 60,
                          label: '60 min'
                        },
                        {
                          value: 65,
                          label: '65 min'
                        },
                        {
                          value: 70,
                          label: '70 min'
                        },
                        {
                          value: 75,
                          label: '75 min'
                        },
                        {
                          value: 80,
                          label: '80 min'
                        },
                        {
                          value: 85,
                          label: '85 min'
                        },
                        {
                          value: 90,
                          label: '90 min'
                        },
                        {
                          value: 95,
                          label: '95 min'
                        },
                        {
                          value: 100,
                          label: '100 min'
                        },
                        {
                          value: 105,
                          label: '105 min'
                        },
                        {
                          value: 110,
                          label: '110 min'
                        },
                        {
                          value: 115,
                          label: '115 min'
                        },
                        {
                          value: 120,
                          label: '120 min'
                        },
                        {
                          value: 125,
                          label: '125 min'
                        },
                        {
                          value: 130,
                          label: '130 min'
                        },
                        {
                          value: 135,
                          label: '135 min'
                        },
                        {
                          value: 140,
                          label: '140 min'
                        },
                        {
                          value: 145,
                          label: '145 min'
                        },
                        {
                          value: 150,
                          label: '150 min'
                        },
                        {
                          value: 155,
                          label: '155 min'
                        },
                        {
                          value: 160,
                          label: '160 min'
                        },
                        {
                          value: 165,
                          label: '165 min'
                        },
                        {
                          value: 170,
                          label: '170 min'
                        },
                        {
                          value: 175,
                          label: '175 min'
                        },
                        {
                          value: 180,
                          label: '180 min'
                        }
                      ]}
                    />
                  </UIFormControlBox>
                </Box>

                <UIFormControlBox
                  label={'Note'}
                  subtitle={'Saranno visibili solo agli altri medici'}
                  error={Boolean(touched.note && errors.note)}
                >
                  <UITextarea value={values?.note} onChange={(event) => setFieldValue('note', event.target.value)} expandable={false} />
                </UIFormControlBox>
              </UIModalBody>
              <UIModalButtons>
                <UIButton type={'submit'} onClick={() => submitForm()} icon={<IconCheck stroke={'1.64'} size={'26px'} />} />

                {appointment?.id && (
                  <UIButton
                    type={'submit'}
                    onClick={() => setDeleteMode(true)}
                    icon={<IconTrash stroke={'1.64'} size={'26px'} />}
                    sxCustom={{
                      bgcolor: '#FF646C'
                    }}
                  />
                )}
              </UIModalButtons>
            </form>
          )}
        </Formik>
      </UiModalInternal>
    </UIModal>
  );
};

const CustomDayPicker = (day) => {
  let colorIndex = -1;
  let office_id = day?.hours?.find((hour) => hour.day === day.day?.format('YYYY-MM-DD'))?.office_id;

  if (office_id) {
    colorIndex = day?.offices?.findIndex((office) => office.id === office_id);

    if (colorIndex > colors.length) {
      colorIndex = colorIndex - colors.length;
    }
  }

  return (
    <PickersDay
      sx={{ bgcolor: colors[colorIndex] ?? 'white' }}
      day={day.day}
      isFirstVisibleCell={day.isFirstVisibleCell}
      isLastVisibleCell={day.isLastVisibleCell}
      onDaySelect={day.onDaySelect}
      outsideCurrentMonth={day.outsideCurrentMonth}
    />
  );
};

export default ModalAppointmentEdit;
