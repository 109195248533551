import { Box, Image } from '@chakra-ui/react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography
} from '@mui/material';
import { IconCheck, IconEdit, IconPlus, IconTrash, IconTrashX } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useShop } from '../../../hooks/Shop/useShop';
import ModalEditVariant from './modalEditVariant';
import DropzoneFormikInput from '../../utilities/Dropzone';
import { UIModal, UIModalBody, UIModalButtons, UIModalHeader, UiModalInternal } from '../../utilities/Modal';
import { UiDialog } from '../../utilities/Dialog';
import UITypography from '../../utilities/Typography';
import { UIFormControlBox } from '../../utilities/Form';
import { UICheckbox, UIInput, UITextarea } from '../../utilities/Input';
import { UIButton } from '../../utilities/Button';

const types = ['function', 'ingredient', 'protocol', 'brand'];

const ModalEdit = ({ modalOpen, handleModalClose, product }) => {
  let [deleteMode, setDeleteMode] = useState(false);
  let [productData, setProductData] = useState(null);
  let [categories, setCategories] = useState({});
  let [editVariantMode, setEditVariantMode] = useState(false);
  let [selectedVariant, setSelectedVariant] = useState(null);

  const { onGetShopProductAdmin, onUpdateShopProduct, onCreateShopProduct, onDeleteShopProduct, onListShopCategories } = useShop();

  const getProduct = async () => {
    onGetShopProductAdmin({
      product_uuid: product.uuid
    }).then((res) => {
      let categories = [];
      res?.categories?.forEach((category) => {
        if (!categories?.[category?.type]) {
          categories[category?.type] = [];
        }
        categories?.[category?.type]?.push(category.uuid);
      });

      res.categories = categories;
      setDeleteMode(false);
      setProductData(res);
    });
  };

  const listCategories = async () => {
    onListShopCategories().then((res) => {
      let new_res = {};

      res?.forEach((category) => {
        if (new_res[category.type]) {
          new_res?.[category.type]?.push(category);
        } else {
          new_res[category.type] = [category];
        }
      });

      setCategories(new_res);
    });
  };

  const handleDeleteVariant = async ({ uuid, variants, setFieldValue }) => {
    let new_array = variants?.map((item) => {
      if (item.uuid === uuid) {
        return {
          ...item,
          deleteItem: true
        };
      } else {
        return item;
      }
    });
    setFieldValue('variants', new_array);
  };

  const handleDeleteMedia = async ({ uuid, medias, setFieldValue }) => {
    let new_array = medias?.map((item) => {
      if (item.uuid === uuid) {
        return {
          ...item,
          deleteItem: true
        };
      } else {
        return item;
      }
    });
    setFieldValue('media', new_array);
  };

  useEffect(() => {
    if (product?.uuid) {
      getProduct().then(() => {
        listCategories().then(() => {});
      });
    } else {
      setProductData(null);
      listCategories().then(() => {});
    }
  }, [product]);

  const handleDeleteProduct = async () => {
    await onDeleteShopProduct({
      product_uuid: product.uuid
    });
    setDeleteMode(false);
    handleModalCloseInternal();
  };

  const handleSubmit = async (values) => {
    const {
      name,
      description,
      ingredients,
      highlighted,
      active,
      categories_function,
      categories_protocol,
      categories_brand,
      categories_ingredient,
      media,
      variants
    } = values;

    let categories = categories_brand?.concat(categories_function, categories_protocol, categories_ingredient);
    let variants_final = variants?.map((variant) => {
      return {
        uuid: variant.uuid,
        name: variant.name,
        size: variant.size,
        price: Number(variant.price),
        quantity: Number(variant.quantity),
        active: variant.active,
        newItem: variant.newItem,
        deleteItem: variant.deleteItem
      };
    });

    let media_final = media?.map((media) => {
      return {
        uuid: media.uuid,
        media_link: media.media_link,
        newItem: media.newItem,
        deleteItem: media.deleteItem
      };
    });

    if (productData?.uuid) {
      onUpdateShopProduct({
        product_uuid: productData?.uuid,
        product_data: {
          name,
          description,
          ingredients,
          categories,
          highlighted,
          active,
          media: media_final,
          variants: variants_final
        }
      }).then((res) => {
        if (res?.response) {
          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          handleModalCloseInternal();
        }
      });
    } else {
      onCreateShopProduct({
        product_data: {
          name,
          description,
          ingredients,
          categories,
          highlighted,
          active,
          media: media_final,
          variants: variants_final
        }
      }).then((res) => {
        if (res?.response) {
          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          handleModalCloseInternal();
        }
      });
    }
  };

  const handleEditVariantClose = () => {
    setEditVariantMode(false);
  };

  const handleModalCloseInternal = () => {
    setDeleteMode(false);
    setProductData(null);
    setSelectedVariant(null);
    handleModalClose();
  };

  return (
    <UIModal open={modalOpen} onClose={handleModalCloseInternal} allowFullScreen={true} className={'modal'}>

      <UiDialog
        type={'default'}
        variant={'cancel'}
        title={'Vuoi eliminare definitivamente il prodotto?'}
        subtitle={'Questa azione non può essere annullata.'}
        open={deleteMode}
        onClose={() => setDeleteMode(false)}
        onConfirm={handleDeleteProduct}
      />



          <Formik
            initialValues={{
              name: productData?.name ?? '',
              description: productData?.description ?? '',
              ingredients: productData?.ingredients ?? '',
              categories_function: productData?.categories?.function ?? [],
              categories_protocol: productData?.categories?.protocol ?? [],
              categories_ingredient: productData?.categories?.ingredient ?? [],
              categories_brand: productData?.categories?.brand ?? [],
              variants: productData?.variants ?? [],
              media: productData?.media ?? [],
              highlighted: productData?.highlighted ?? false,
              active: productData?.active ?? false
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Il nome è obbligatorio'),
              description: Yup.string().required('La descrizione è obbligatoria'),
              ingredients: Yup.string().required('Gli ingredienti sono obbligatori'),
              categories_function: Yup.array().nullable(),
              categories_protocol: Yup.array().nullable(),
              categories_ingredient: Yup.array().nullable(),
              categories_brand: Yup.array().nullable(),
              variants: Yup.array().nullable(),
              highlighted: Yup.boolean().nullable(),
              active: Yup.boolean().nullable()
            })}
            onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
            enableReinitialize={true}
          >
            {({ errors, handleSubmit, touched, values, setFieldValue, submitForm }) => (
              <form onSubmit={handleSubmit} id={'appointment-form'}>

                <ModalEditVariant
                  modalOpen={editVariantMode}
                  handleModalClose={handleEditVariantClose}
                  variant={selectedVariant}
                  variants={values.variants}
                  setFieldValue={setFieldValue}
                />

                <UiModalInternal onClose={handleModalCloseInternal} isLoading={product && productData === null} containerSx={{
                  width: '80%',
                  maxWidth: '800px'
                }}>
                  <UIModalHeader sx={{ border: 'none', p: '50px 0 0 0' }}>
                    <UITypography
                      variant={'Oxygen700'}
                      title={`Dati prodotto`}
                      size={'18px'}
                      sxCustom={{
                        textAlign: 'center',
                        color: '#101828'
                      }}
                    />

                  </UIModalHeader>
                  <UIModalBody
                    sx={{
                      bgcolor: 'transparent'
                    }}
                  >

                    <UIFormControlBox label={'Nome'} error={Boolean(touched.name && errors.name)}
                                      error_message={errors?.name}>
                      <UIInput value={values?.name} onChange={(e) => setFieldValue('name', e.target.value)} />
                    </UIFormControlBox>

                    <UIFormControlBox label={'Descrizione'} error={Boolean(touched.description && errors.description)}
                                      error_message={errors?.description}>
                      <UITextarea
                        name={'description'}
                        value={values?.description}
                        onChange={(event) => setFieldValue('description', event.target.value)}
                        expandable={false}
                      />
                    </UIFormControlBox>

                    <UIFormControlBox label={'Ingredienti'} error={Boolean(touched.ingredients && errors.ingredients)}
                                      error_message={errors?.ingredients}>
                      <UITextarea
                        name={'ingredients'}
                        value={values?.ingredients}
                        onChange={(event) => setFieldValue('ingredients', event.target.value)}
                        expandable={false}
                      />
                    </UIFormControlBox>

                      {types?.map((type, index) => (
                        <SelectCategoryType
                          key={index}
                          type={type}
                          categories={categories}
                          errors={errors}
                          touched={touched}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      ))}

                    <UIFormControlBox label={'Varianti'} error={Boolean(touched.variants && errors.variants)}
                                      error_message={errors?.variants}>
                      <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                        {values?.variants?.map((variant, index) => (
                          <VariantCard
                            kind={'edit'}
                            variant={variant}
                            key={index}
                            setEditVariantMode={setEditVariantMode}
                            setSelectedVariant={setSelectedVariant}
                            handleDeleteVariant={handleDeleteVariant}
                            variants={values?.variants}
                            setFieldValue={setFieldValue}
                          />
                        ))}
                        <VariantCard kind={'add'} setEditVariantMode={setEditVariantMode}
                                     setSelectedVariant={setSelectedVariant} />
                      </Box>
                    </UIFormControlBox>

                    <UIFormControlBox label={'Immagini prodotto'} error={Boolean(touched.variants && errors.variants)}
                                      error_message={errors?.variants}>
                      <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'} overflow={'auto'}>
                        {values?.media?.map((media, index) => (
                          <MediaCard
                            key={index}
                            kind={'edit'}
                            media={media}
                            medias={values?.media}
                            setFieldValue={setFieldValue}
                            handleDeleteMedia={handleDeleteMedia}
                            productUuid={productData?.uuid}
                          />
                        ))}
                        <MediaCard medias={values?.media} setFieldValue={setFieldValue} productUuid={productData?.uuid} />
                      </Box>
                    </UIFormControlBox>

                    <UIFormControlBox>
                      <UICheckbox
                        label={<UITypography title={'In primo piano'} />}
                        name={'highlighted'}
                        readOnly={true}
                        checked={values?.highlighted}
                        onChange={(e) => setFieldValue('highlighted', e.target.checked)}
                      />
                    </UIFormControlBox>

                    <UIFormControlBox>
                      <UICheckbox
                        label={<UITypography title={'Attivo'} />}
                        name={'active'}
                        readOnly={true}
                        checked={values?.active}
                        onChange={(e) => setFieldValue('active', e.target.checked)}
                      />
                    </UIFormControlBox>
                  </UIModalBody>
                  <UIModalButtons>
                    <UIButton type={'submit'} onClick={() => submitForm()}
                              icon={<IconCheck stroke={'1.64'} size={'26px'} />} />
                    <UIButton
                      type={'submit'}
                      onClick={() => {
                        setDeleteMode(true);
                      }}
                      icon={<IconTrash stroke={1.64} size="25px" />}
                      sxCustom={{
                        bgcolor: '#FF646C'
                      }}
                    />
                  </UIModalButtons>
                </UiModalInternal>
              </form>
            )}
          </Formik>
    </UIModal>
  );
};

const SelectCategoryType = ({ index, type, categories, touched, errors, values, setFieldValue }) => {
  let [title, setTitle] = useState('');

  useEffect(() => {
    switch (type) {
      case 'function':
        setTitle('Funzioni');
        break;
      case 'protocol':
        setTitle('Protocolli');
        break;
      case 'ingredient':
        setTitle('Ingredienti');
        break;
      case 'brand':
        setTitle('Marca');
        break;
      default:
        setTitle('Categoria');
        break;
    }
  }, [type, categories]);

  return (
    <Box display={'flex'} mt={5} p={'10px'} alignItems={'center'} key={index}>
      <Box ml={10} w={'85%'}>
        <FormControl fullWidth error={Boolean(touched.categories && errors.categories)}>
          <InputLabel id="categories-label">{title}</InputLabel>
          <Select
            variant={'filled'}
            labelId="categories-label"
            id="categories-select"
            multiple={!(type === 'brand')}
            value={values['categories_' + type]}
            onChange={(event) => {
              if (type === 'brand') {
                setFieldValue('categories_' + type, [event.target.value]);
              } else {
                setFieldValue('categories_' + type, event.target.value);
              }
            }}
            input={<OutlinedInput id="categories-input" label="Categorie" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected?.map((value, index) => (
                  <ChipCustom key={index} type={type} value={value} categories={categories} />
                ))}
              </Box>
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 48 * 4.5 + 8,
                  width: 250
                }
              }
            }}
          >
            {categories[type]?.map((category, index) => (
              <MenuItem key={index} value={category?.uuid}>
                {category?.name}
              </MenuItem>
            ))}
          </Select>

          {touched.categories && errors.categories && (
            <FormHelperText error id="standard-weight-helper-text-email-login">
              {errors.categories}
            </FormHelperText>
          )}
        </FormControl>
      </Box>
    </Box>
  );
};

const ChipCustom = ({ categories, value, type }) => {
  let [label, setLabel] = useState('');

  useEffect(() => {
    categories[type]?.forEach((category) => {
      if (category?.uuid === value) setLabel(category?.name);
    });
  }, [value, categories, type]);

  if (!label) return <></>;
  return <Chip key={value} value={value} label={label} />;
};

const VariantCard = ({ variant, key, kind, setEditVariantMode, setSelectedVariant, handleDeleteVariant, variants, setFieldValue }) => {
  if (variant?.deleteItem) return <></>;

  return (
    <>
      {kind === 'edit' ? (
        <Card key={key} sx={{ mr: 2 }}>
          <CardContent
            sx={{
              py: 2
            }}
          >
            <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
              {variant?.name}
            </Typography>
            <Typography variant="h6" color={'text.secondary'}>
              {variant?.size}
            </Typography>
          </CardContent>
          <CardActions
            sx={{
              py: 1,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Box w={'25px'}>
              <Button
                style={{
                  minWidth: '25px',
                  padding: '0px'
                }}
                fullWidth
                variant={'text'}
                onClick={() => {
                  setSelectedVariant(variant);
                  setEditVariantMode(true);
                }}
              >
                <IconEdit stroke={1.5} size="1rem" />
              </Button>
            </Box>
            <Box w={'25px'}>
              <Button
                style={{
                  minWidth: '25px',
                  padding: '0px'
                }}
                fullWidth
                variant={'text'}
                color={'error'}
                onClick={() => handleDeleteVariant({ uuid: variant?.uuid, variants: variants, setFieldValue: setFieldValue })}
              >
                <IconTrashX stroke={1.5} size="1rem" />
              </Button>
            </Box>
          </CardActions>
        </Card>
      ) : (
        <Card sx={{ mr: 2, backgroundColor: 'transparent' }}>
          <CardContent>
            <Button
              variant={'text'}
              onClick={() => {
                setSelectedVariant(null);
                setEditVariantMode(true);
              }}
              sx={{
                backgroundColor: '#2196f3',
                color: 'white',
                width: '25px',
                height: '45px',
                minWidth: '45px',
                borderRadius: '50%'
              }}
            >
              <IconPlus stroke={2} size="1rem" />
            </Button>
          </CardContent>
        </Card>
      )}
    </>
  );
};

const MediaCard = ({ media, key, kind, handleDeleteMedia, medias, setFieldValue, productUuid }) => {
  if (media?.deleteItem) return <></>;

  return (
    <>
      {kind === 'edit' ? (
        <Card key={key} sx={{ mr: 2, minWidth: '150px' }}>
          <CardContent
            sx={{
              py: 2,
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Image src={media?.media_link} alt={media?.uuid} width={100} height={100} />
          </CardContent>
          <CardActions
            sx={{
              py: 1,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Box w={'25px'}>
              <Button
                style={{
                  minWidth: '25px',
                  padding: '0px'
                }}
                fullWidth
                variant={'text'}
                color={'error'}
                onClick={() => handleDeleteMedia({ uuid: media?.uuid, medias: medias, setFieldValue: setFieldValue })}
              >
                <IconTrashX stroke={1.5} size="1rem" />
              </Button>
            </Box>
          </CardActions>
        </Card>
      ) : (
        <Card sx={{ mr: 2, backgroundColor: 'transparent', minWidth: '150px' }}>
          <CardContent>
            <DropzoneFormikInput
              variant={'shop_product_media'}
              field={'stamp'}
              folder={productUuid}
              label={'Carica Immagine'}
              extra_data={{ product_uuid: productUuid ?? null }}
              callback={(key, uuid) => {
                if (!productUuid) {
                  setFieldValue('media', [...medias, { uuid: uuid, media_link: key, newItem: true }]);
                } else {
                  setFieldValue('media', [...medias, { uuid: uuid, media_link: key }]);
                }
              }}
            />
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default ModalEdit;
