import {
  ADD_PATIENT_DOCTOR,
  BAN_USER_DOCTOR,
  COUNT_MINE_REVIEWS,
  CREATE_DOCTOR_ADMIN,
  CREATE_USER_ADMIN,
  DELETE_USER_MEDIA,
  GET_DOCTOR_ADMIN,
  GET_DOCTOR_REVIEW,
  GET_USER_ADMIN,
  LIST_DOCTORS_ADMIN,
  LIST_DOCTORS_ADMIN_PAGINATED,
  LIST_DOCTORS_REVIEWS,
  LIST_USERS_ADMIN,
  LIST_USERS_ADMIN_PAGINATED,
  LIST_USERS_BANNED_PAGINATED,
  SET_HIDDEN_REVIEW,
  SET_REVIEW_REPORTS_IGNORED,
  UNBAN_USER_DOCTOR,
  UPDATE_DOCTOR_ADMIN,
  UPDATE_MINE_USER,
  UPDATE_REVIEW,
  UPDATE_USER_ADMIN,
  UPDATE_USER_DOCTOR,
  UPLOAD_ACCOUNT_MEDIA,
  UPLOAD_PROFILE_PIC
} from './graphql/queries';
import { useMutation } from '../useMutation';
import { useQuery } from '../useQuery';

export const useAccounts = () => {
  //! USERS
  const { getDataGQL: listUsersAdmin } = useQuery(LIST_USERS_ADMIN);
  const { getDataGQL: listUsersAdminPaginated } = useQuery(LIST_USERS_ADMIN_PAGINATED);
  const { getDataGQL: getUserAdmin } = useQuery(GET_USER_ADMIN);
  const { getDataGQL: createUserAdmin } = useMutation(CREATE_USER_ADMIN);
  const { getDataGQL: updateMineUser } = useMutation(UPDATE_MINE_USER);
  const { getDataGQL: updateUserAdmin } = useMutation(UPDATE_USER_ADMIN);
  const { getDataGQL: updateUserDoctor } = useMutation(UPDATE_USER_DOCTOR);
  const { getDataGQL: uploadProfilePic } = useMutation(UPLOAD_PROFILE_PIC);
  const { getDataGQL: uploadAccountMedia } = useMutation(UPLOAD_ACCOUNT_MEDIA);
  const { getDataGQL: deleteUserMedia } = useMutation(DELETE_USER_MEDIA);
  const { getDataGQL: addPatientDoctor } = useMutation(ADD_PATIENT_DOCTOR);

  //! USERS BANNED
  const { getDataGQL: listUsersBannedPaginated } = useQuery(LIST_USERS_BANNED_PAGINATED);
  const { getDataGQL: banUserDoctor } = useMutation(BAN_USER_DOCTOR);
  const { getDataGQL: unbanUserDoctor } = useMutation(UNBAN_USER_DOCTOR);

  //! DOCTORS
  const { getDataGQL: listDoctorsAdmin } = useQuery(LIST_DOCTORS_ADMIN);
  const { getDataGQL: listDoctorsAdminPaginated } = useQuery(LIST_DOCTORS_ADMIN_PAGINATED);
  const { getDataGQL: getDoctorAdmin } = useQuery(GET_DOCTOR_ADMIN);
  const { getDataGQL: createDoctorAdmin } = useMutation(CREATE_DOCTOR_ADMIN);
  const { getDataGQL: updateDoctorAdmin } = useMutation(UPDATE_DOCTOR_ADMIN);

  //! DOCTORS REVIEW
  const { getDataGQL: listDoctorReviews } = useQuery(LIST_DOCTORS_REVIEWS);
  const { getDataGQL: countMineReviews } = useQuery(COUNT_MINE_REVIEWS);
  const { getDataGQL: getDoctorReview } = useQuery(GET_DOCTOR_REVIEW);
  const { getDataGQL: setIgnoredReviewReports } = useMutation(SET_REVIEW_REPORTS_IGNORED);
  const { getDataGQL: setHiddenReview } = useMutation(SET_HIDDEN_REVIEW);
  const { getDataGQL: updateReviewAdmin } = useMutation(UPDATE_REVIEW);

  const onUpdateDoctorAdmin = async ({ doctor_id, doctor_data }) => {
    const response = await updateDoctorAdmin({
      variables: {
        doctor_id,
        doctor_data
      }
    });
    if (response?.adminRoute?.updateDoctorAdmin) {
      return response?.adminRoute?.updateDoctorAdmin;
    }
    return null;
  };

  const onListUsersAdmin = async ({ filters = {} } = {}) => {
    const response = await listUsersAdmin({
      variables: {
        filters
      }
    });
    if (response?.adminRoute?.listUsersAdmin) {
      return response?.adminRoute?.listUsersAdmin;
    }
    return null;
  };

  const onListUsersAdminPaginated = async ({ filters = {} } = {}) => {
    const response = await listUsersAdminPaginated({
      variables: {
        filters
      }
    });
    if (response?.adminRoute?.listUsersAdminPaginated) {
      return response?.adminRoute?.listUsersAdminPaginated;
    }
    return null;
  };

  const onGetUserAdmin = async ({ user_uuid } = {}) => {
    const response = await getUserAdmin({
      variables: {
        user_uuid
      }
    });
    if (response?.adminRoute?.getUserAdmin) {
      return response?.adminRoute?.getUserAdmin;
    }
    return null;
  };

  const onCreateUserAdmin = async ({ user_data } = {}) => {
    const response = await createUserAdmin({
      variables: {
        user_data
      }
    });
    if (response?.adminRoute?.createUserAdmin) {
      return response?.adminRoute?.createUserAdmin;
    }
    return null;
  };

  const onUpdateUserAdmin = async ({ user_id, user_data } = {}) => {
    const response = await updateUserAdmin({
      variables: {
        user_id,
        user_data
      }
    });
    if (response?.adminRoute?.updateUserAdmin) {
      return response?.adminRoute?.updateUserAdmin;
    }
    return null;
  };

  const onUpdateUserDoctor = async ({ user_id, user_data } = {}) => {
    const response = await updateUserDoctor({
      variables: {
        user_id,
        user_data
      }
    });
    if (response?.adminRoute?.updateUserDoctor) {
      return response?.adminRoute?.updateUserDoctor;
    }
    return null;
  };

  const onUpdateMineUser = async ({ user_data } = {}) => {
    const response = await updateMineUser({
      variables: {
        user_data
      }
    });
    if (response?.adminRoute?.updateMineUser) {
      return response?.adminRoute?.updateMineUser;
    }
    return null;
  };

  const onUploadProfilePic = async ({ account_uuid, filename, contentType } = {}) => {
    const response = await uploadProfilePic({
      variables: {
        filename,
        contentType,
        account_uuid
      }
    });
    if (response?.adminRoute?.uploadProfilePicAdmin) {
      return response?.adminRoute?.uploadProfilePicAdmin;
    }
    return null;
  };

  const onUploadAccountMedia = async ({ account_uuid, filename, contentType } = {}) => {
    const response = await uploadAccountMedia({
      variables: {
        account_uuid,
        filename,
        contentType
      }
    });
    if (response?.adminRoute?.uploadAccountMedia) {
      return response?.adminRoute?.uploadAccountMedia;
    }
    return null;
  };

  const onDeleteUserMedia = async ({ media_id } = {}) => {
    const response = await deleteUserMedia({
      variables: {
        media_id
      }
    });
    if (response?.adminRoute?.deleteUserMedia) {
      return response?.adminRoute?.deleteUserMedia;
    }
    return null;
  };

  const onAddPatientDoctor = async (user_data = {}) => {
    const response = await addPatientDoctor({
      variables: {
        user_data
      }
    });
    if (response?.adminRoute?.addPatientDoctor) {
      return response?.adminRoute?.addPatientDoctor;
    }
    return null;
  }

  //! USERS BANNED
  const onListUsersBannedPaginated = async ({ filters = {} } = {}) => {
    const response = await listUsersBannedPaginated({
      variables: {
        filters
      }
    });
    if (response?.adminRoute?.listUsersBannedPaginated) {
      return response?.adminRoute?.listUsersBannedPaginated;
    }
    return null;
  };

  const onBanUserDoctor = async ({ user_uuid } = {}) => {
    const response = await banUserDoctor({
      variables: {
        user_uuid
      }
    });
    if (response?.adminRoute?.banUserDoctor) {
      return response?.adminRoute?.banUserDoctor;
    }
    return null;
  };

  const onUnbanUserDoctor = async ({ user_uuid } = {}) => {
    const response = await unbanUserDoctor({
      variables: {
        user_uuid
      }
    });
    if (response?.adminRoute?.unbanUserDoctor) {
      return response?.adminRoute?.unbanUserDoctor;
    }
    return null;
  };

  //! DOCTORS
  const onListDoctors = async () => {
    const response = await listDoctorsAdmin();
    if (response?.adminRoute?.listDoctorsAdmin) {
      return response?.adminRoute?.listDoctorsAdmin;
    }
    return null;
  };

  const onListDoctorsPaginated = async ({ filters }) => {
    const response = await listDoctorsAdminPaginated({
      variables: {
        filters
      }
    });
    if (response?.adminRoute?.listDoctorsAdminPaginated) {
      return response?.adminRoute?.listDoctorsAdminPaginated;
    }
    return null;
  };

  const onGetDoctorAdmin = async ({ doctor_uuid } = {}) => {
    const response = await getDoctorAdmin({
      variables: {
        doctor_uuid
      }
    });
    if (response?.adminRoute?.getDoctorAdmin) {
      return response?.adminRoute?.getDoctorAdmin;
    }
    return null;
  };

  const onCreateDoctorAdmin = async ({ doctor_data } = {}) => {
    const response = await createDoctorAdmin({
      variables: {
        doctor_data
      }
    });
    if (response?.adminRoute?.createDoctorAdmin) {
      return response?.adminRoute?.createDoctorAdmin;
    }
    return null;
  };

  //! DOCTORS REVIEW
  const onListDoctorReviews = async ({
    doctor_id = null,
    patient_id = null,
    reported = null,
    rating = null,
    page = 1,
    limit = 20
  } = {}) => {
    const response = await listDoctorReviews({
      variables: {
        doctor_id,
        patient_id,
        reported,
        rating,
        page,
        limit
      }
    });
    if (response?.adminRoute?.listDoctorReviews) {
      return response?.adminRoute?.listDoctorReviews;
    }
    return null;
  };

  const onCountMineReviews = async () => {
    const response = await countMineReviews();
    if (response?.adminRoute?.countMineReviews) {
      return response?.adminRoute?.countMineReviews;
    }
    return null;
  };

  const onGetDoctorReview = async ({ review_id } = {}) => {
    const response = await getDoctorReview({
      variables: {
        review_id
      }
    });
    if (response?.adminRoute?.getDoctorReview) {
      return response?.adminRoute?.getDoctorReview;
    }
    return null;
  };

  const onSetIgnoredReviewReports = async ({ review_id } = {}) => {
    const response = await setIgnoredReviewReports({
      variables: {
        review_id
      }
    });
    if (response?.adminRoute?.setIgnoredReviewReports) {
      return response?.adminRoute?.setIgnoredReviewReports;
    }
    return null;
  };

  const onSetHiddenReview = async ({ review_id } = {}) => {
    const response = await setHiddenReview({
      variables: {
        review_id
      }
    });
    if (response?.adminRoute?.setHiddenReview) {
      return response?.adminRoute?.setHiddenReview;
    }
    return null;
  };

  const onUpdateReview = async ({ review_id, review_data } = {}) => {
    const response = await updateReviewAdmin({
      variables: {
        review_id,
        review_data
      }
    });
    if (response?.adminRoute?.updateReviewAdmin) {
      return response?.adminRoute?.updateReviewAdmin;
    }
    return null;
  };

  return {
    //! USERS
    onListUsersAdmin,
    onListUsersAdminPaginated,
    onGetUserAdmin,
    onCreateUserAdmin,
    onUpdateUserAdmin,
    onUpdateUserDoctor,
    onUpdateMineUser,
    onUploadAccountMedia,
    onUploadProfilePic,
    onDeleteUserMedia,
    onAddPatientDoctor,

    //! USERS BANNED
    onListUsersBannedPaginated,
    onBanUserDoctor,
    onUnbanUserDoctor,

    //! DOCTORS
    onListDoctors,
    onListDoctorsPaginated,
    onGetDoctorAdmin,
    onCreateDoctorAdmin,
    onUpdateDoctorAdmin,

    //! DOCTORS REVIEW
    onListDoctorReviews,
    onCountMineReviews,
    onGetDoctorReview,
    onSetIgnoredReviewReports,
    onSetHiddenReview,
    onUpdateReview
  };
};
