import gql from 'graphql-tag';

//! USERS
export const LIST_USERS_ADMIN = gql`
  query listUsersAdmin($filters: mixedFilters) {
    adminRoute {
      listUsersAdmin(filters: $filters) {
        id
        uuid
        name
        surname
        sex
        email
        profile_pic
        doctor_specialization
        instagram_link
        formation
        about_me
        phone_country {
          dial_code
        }
        phone
        active
        calendars {
          id
          office_id
          active
        }
        media {
          id
          media_link
        }
      }
    }
  }
`;

export const LIST_USERS_ADMIN_PAGINATED = gql`
  query listUsersAdminPaginated($filters: mixedFilters) {
    adminRoute {
      listUsersAdminPaginated(filters: $filters) {
        count
        rows {
          id
          uuid
          name
          surname
          sex
          phone
          phone_country {
            id
            name
            dial_code
          }
          email
          profile_pic
          doctor_specialization
          instagram_link
          formation
          about_me
          active
          first_login
          calendars {
            id
            office_id
            active
          }
          media {
            id
            media_link
          }
        }
      }
    }
  }
`;

export const GET_USER_ADMIN = gql`
  query getUserAdmin($user_uuid: String!) {
    adminRoute {
      getUserAdmin(user_uuid: $user_uuid) {
        id
        uuid
        name
        surname
        sex
        age
        allergies
        other_allergies
        anamnesi
        email
        phone
        dial_code_country
        pregnant
        permanent_makeup
        private_note
        phone_country {
          dial_code
        }
        profile_pic
        doctor_specialization
        instagram_link
        formation
        about_me
        active
        calendars {
          id
          office_id
          active
        }
        media {
          id
          media_link
        }
      }
    }
  }
`;

export const CREATE_USER_ADMIN = gql`
  query createUserAdmin($user_data: UserInput!) {
    adminRoute {
      createUserAdmin(user_data: $user_data) {
        responseStatus
        response
      }
    }
  }
`;

export const UPDATE_MINE_USER = gql`
  query updateMineUser($user_data: DoctorInput!) {
    adminRoute {
      updateMineUser(user_data: $user_data) {
        responseStatus
        response
      }
    }
  }
`;

export const UPDATE_USER_ADMIN = gql`
  query updateUserAdmin($user_id: ID!, $user_data: UserInput!) {
    adminRoute {
      updateUserAdmin(user_data: $user_data, user_id: $user_id) {
        responseStatus
        response
      }
    }
  }
`;

export const UPDATE_USER_DOCTOR = gql`
  query updateUserDoctor($user_id: ID!, $user_data: UserInputDoctor!) {
    adminRoute {
      updateUserDoctor(user_data: $user_data, user_id: $user_id) {
        responseStatus
        response
      }
    }
  }
`;

export const UPLOAD_PROFILE_PIC = gql`
  query uploadProfilePicAdmin($account_uuid: String!, $filename: String!, $contentType: String!) {
    adminRoute {
      uploadProfilePicAdmin(account_uuid: $account_uuid, filename: $filename, contentType: $contentType) {
        presignedUrl
        key
      }
    }
  }
`;

export const UPLOAD_ACCOUNT_MEDIA = gql`
  query uploadAccountMedia($account_uuid: String!, $filename: String!, $contentType: String!) {
    adminRoute {
      uploadAccountMedia(account_uuid: $account_uuid, filename: $filename, contentType: $contentType) {
        presignedUrl
        key
      }
    }
  }
`;

export const DELETE_USER_MEDIA = gql`
  query deleteUserMedia($media_id: ID!) {
    adminRoute {
      deleteUserMedia(media_id: $media_id) {
        responseStatus
        response
      }
    }
  }
`;

export const ADD_PATIENT_DOCTOR = gql`
  query addPatientDoctor($user_data: UserAddPatient!) {
    adminRoute {
      addPatientDoctor(user_data: $user_data) {
        responseStatus
        response
      }
    }
  }
`;

export const LIST_DOCTORS_ADMIN = gql`
  query listDoctorsAdmin {
    adminRoute {
      listDoctorsAdmin {
        id
        uuid
        name
        surname
        sex
        email
        profile_pic
        doctor_specialization
        instagram_link
        formation
        about_me
        active
        calendars {
          id
          office_id
          active
        }
      }
    }
  }
`;

export const LIST_DOCTORS_ADMIN_PAGINATED = gql`
  query listDoctorsAdmin($filters: mixedFilters) {
    adminRoute {
      listDoctorsAdminPaginated(filters: $filters) {
        rows {
          id
          uuid
          name
          surname
          sex
          email
          profile_pic
          doctor_specialization
          instagram_link
          formation
          about_me
          active
          calendars {
            id
            office_id
            active
          }
        }
        count
      }
    }
  }
`;

export const GET_DOCTOR_ADMIN = gql`
  query getDoctorAdmin($doctor_uuid: String!) {
    adminRoute {
      getDoctorAdmin(doctor_uuid: $doctor_uuid) {
        id
        uuid
        name
        surname
        sex
        email
        phone
        phone_country {
          dial_code
        }
        profile_pic
        doctor_specialization
        instagram_link
        formation
        about_me
        active
        calendars {
          id
          office_id
          active
        }
        media {
          id
          media_link
        }
      }
    }
  }
`;

export const CREATE_DOCTOR_ADMIN = gql`
  query createDoctorAdmin($doctor_data: DoctorInput!) {
    adminRoute {
      createDoctorAdmin(doctor_data: $doctor_data) {
        responseStatus
        response
      }
    }
  }
`;

export const UPDATE_DOCTOR_ADMIN = gql`
  query updateDoctorAdmin($doctor_id: ID!, $doctor_data: DoctorInput!) {
    adminRoute {
      updateDoctorAdmin(doctor_id: $doctor_id, doctor_data: $doctor_data) {
        responseStatus
        response
      }
    }
  }
`;

//! DOCTORS REVIEWS
export const LIST_DOCTORS_REVIEWS = gql`
  query listDoctorsReviews($doctor_id: ID, $patient_id: ID, $reported: Boolean, $rating: Int, $page: Int, $limit: Int) {
    adminRoute {
      listDoctorReviews(doctor_id: $doctor_id, patient_id: $patient_id, reported: $reported, rating: $rating, page: $page, limit: $limit) {
        count
        rows {
          id
          review
          rating
          createdAt
          appointment {
            calendar {
              doctor {
                id
                name
                surname
              }
            }
            patient {
              id
              name
              surname
            }
          }
          report {
            id
          }
        }
      }
    }
  }
`;

export const COUNT_MINE_REVIEWS = gql`
  query countMineReviews {
    adminRoute {
      countMineReviews
    }
  }
`;

export const GET_DOCTOR_REVIEW = gql`
  query getDoctorReview($review_id: ID!) {
    adminRoute {
      getDoctorReview(review_id: $review_id) {
        id
        review
        rating
        appointment {
          date
          start_hour
          end_hour
          calendar {
            doctor {
              id
              name
              surname
              email
              phone
              phone_country {
                dial_code
              }
            }
            office {
              name
              city {
                name
              }
            }
          }
          patient {
            id
            name
            surname
            email
            phone
            phone_country {
              dial_code
            }
          }
          treatment {
            name
          }
          doctor_treatment {
            price
          }
        }
        report {
          id
          reporter {
            id
            name
            surname
            email
            phone
            phone_country {
              dial_code
            }
          }
        }
      }
    }
  }
`;

export const SET_REVIEW_REPORTS_IGNORED = gql`
  query setIgnoredReviewReports($review_id: ID!) {
    adminRoute {
      setIgnoredReviewReports(review_id: $review_id) {
        responseStatus
        response
      }
    }
  }
`;

export const SET_HIDDEN_REVIEW = gql`
  query setHiddenReview($review_id: ID!) {
    adminRoute {
      setHiddenReview(review_id: $review_id) {
        responseStatus
        response
      }
    }
  }
`;

export const UPDATE_REVIEW = gql`
  query updateReviewAdmin($review_id: ID!, $review_data: AccountReviewInput!) {
    adminRoute {
      updateReviewAdmin(review_id: $review_id, review_data: $review_data) {
        responseStatus
        response
      }
    }
  }
`;

export const LIST_USERS_BANNED_PAGINATED = gql`
  query listUsersBannedPaginated($filters: mixedFilters) {
    adminRoute {
      listUsersBannedPaginated(filters: $filters) {
        count
        rows {
          id
          uuid
          name
          surname
          sex
          phone
          phone_country {
            id
            name
            dial_code
          }
          email
          profile_pic
          doctor_specialization
          instagram_link
          formation
          about_me
          active
          first_login
          calendars {
            id
            office_id
            active
          }
          media {
            id
            media_link
          }
        }
      }
    }
  }
`;

export const BAN_USER_DOCTOR = gql`
  query banUserDoctor($user_uuid: String!) {
    adminRoute {
      banUserDoctor(user_uuid: $user_uuid) {
        responseStatus
        response
      }
    }
  }
`;

export const UNBAN_USER_DOCTOR = gql`
  query unbanUserDoctor($user_uuid: String!) {
    adminRoute {
      unbanUserDoctor(user_uuid: $user_uuid) {
        responseStatus
        response
      }
    }
  }
`;
