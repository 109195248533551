// assets
import { IconAlarm, IconSettings } from '@tabler/icons-react';
// constant
const icons = { IconAlarm, IconSettings };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const settings = {
  id: 'settings',
  type: 'group',
  permission: ['ADMIN'],
  children: [
    {
      id: 'settings_menu',
      title: 'Impostazioni',
      type: 'collapse',
      icon: icons.IconSettings,
      breadcrumbs: false,
      url: '/warnings',
      permission: ['ADMIN'],
      children: [
        {
          id: 'warnings',
          title: 'Avvisi',
          type: 'item',
          url: '/warnings',
          icon: icons.IconAlarm,
          breadcrumbs: false,
          permission: [ 'ADMIN']
        }
      ]
    }
  ]
};

export default settings;
