// project imports
import MainLayout from 'layout/MainLayout';
import { ProtectedRoute } from '../utils/ProtectedRoute';
import WarningsView from '../views/pages/warnings/view';

// dashboard routing

// ==============================|| MAIN ROUTING ||============================== //

const Settings = {
  path: '/',
  element: (
    <ProtectedRoute>
      <MainLayout />
    </ProtectedRoute>
  ),
  children: [
    {
      path: 'warnings',
      element: (
        <ProtectedRoute permission={'ADMIN'}>
          <WarningsView />
        </ProtectedRoute>
      )
    }
  ]
};

export default Settings;
