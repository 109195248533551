import React, { useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { IconCheckbox, IconEdit, IconPlus } from '@tabler/icons-react';
import ModalEdit from './modalEdit';
import UITable from '../../utilities/Table';
import { SectionHeader, UIPageHeader } from '../../utilities/Headers';
import { useSettings } from '../../../hooks/Settings/useSettings';

const WarningsView = () => {
  const [selectedWarning, setSelectedWarning] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);

  const { onListWarningsAdminPaginated } = useSettings();

  const handleModalClose = useCallback(() => {
    setEditMode(false);
    setForceUpdate(true);
  }, []);

  const prepareValues = (warnings) =>
    warnings?.map((warning) => {
      return {
        warning: warning,
        titleEl: (
          <Box>
            <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
              {warning?.text}
            </Typography>
          </Box>
        ),
        activeEl: (
          <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            {warning?.active && <IconCheckbox />}
          </Box>
        ),
        actionsEl: (
          <Box
            onClick={() => {
              setSelectedWarning(warning);
              setEditMode(true);
            }}
          >
            <IconEdit cursor={'pointer'} />
          </Box>
        )
      };
    });

  return (
    <Box className="products-view" w={'100%'} h={'100%'} overflow={'auto'}>
      <Box
        position={'fixed'}
        display={'flex'}
        right={10}
        bottom={10}
        bgcolor={'#2c3e50'}
        p={'10px'}
        borderRadius={'100%'}
        w={'50px'}
        h={'50px'}
        color={'white'}
        justifyContent={'center'}
        alignItems={'center'}
        cursor={'pointer'}
        onClick={() => {
          setSelectedWarning(null);
          setEditMode(true);
        }}
      >
        <IconPlus stroke={3} size="2rem" />
      </Box>
      <ModalEdit modalOpen={editMode} handleModalClose={handleModalClose} warning={selectedWarning} />

      <UIPageHeader
        title={'Avvisi'}
        subtitle={
          'Gestisci qui i tuoi avvisi. In questa lista appariranno tutti gli avvisi che hai creato. Clicca sull\'icona dell\'editor per modificarli.'
        }
      />

      <SectionHeader title={'Ricerca avviso'} subtitle={`E' possibile cercare per titolo.`} />
      <UITable
        columns={[
          {
            name: 'Titolo',
            value: 'titleEl',
          },
          {
            name: 'Attivo',
            value: 'activeEl',
            size: 10
          },
          {
            name: 'Azioni',
            value: 'actionsEl',
            size: 10
          }
        ]}
        prepareValues={prepareValues}
        enableSearch={true}
        enableCustomFilters={true}
        infiniteScrollEndMessage={'Non ci sono altri articoli da mostrare'}
        infiniteScrollFunction={onListWarningsAdminPaginated}
        tableHeight={'calc(100vh - 400px)'}
        forceUpdate={forceUpdate}
        setForceUpdate={setForceUpdate}
      />
    </Box>
  );
};
export default WarningsView;
