import React from 'react';
import 'moment/locale/it';
import { UIModal, UIModalBody, UIModalButtons, UIModalHeader, UiModalInternal } from '../../utilities/Modal';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { UIInput } from '../../utilities/Input';
import { UIFormControlBox } from '../../utilities/Form';
import { UIButton } from '../../utilities/Button';
import { IconCheck } from '@tabler/icons-react';
import { useAccounts } from '../../../hooks/Accounts/useAccounts';
import { toast } from 'react-toastify';
import { UIPageHeader } from '../../utilities/Headers';

const ModalAddPatient = ({ modalOpen, handleModalClose }) => {

  const { onAddPatientDoctor } = useAccounts();

  const handleSubmit = (values) => {
    console.log(values);
    onAddPatientDoctor(values).then((res) => {
      toast(res?.response, {
        style: {
          fontSize: '14px',
          backgroundColor: res?.responseStatus ? '#00e676' : '#FF646C',
          color: '#ffffff'
        }
      });
      if (res?.responseStatus) {
        handleModalCloseInternal();
      }


    });
  };

  const handleModalCloseInternal = () => {
    handleModalClose();
  };

  return (
    <UIModal open={modalOpen} onClose={handleModalCloseInternal} allowFullScreen={true} className={'modal'}>

      <UiModalInternal
        onClose={handleModalCloseInternal}
        containerSx={{
          bgcolor: '#FFF'
        }}
      >
        <Formik
          enableReinitialize={false}
          initialValues={{
            name: '',
            surname: '',
            phone: '',
            email: ''
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Obbligatorio'),
            surname: Yup.string().required('Obbligatorio'),
            phone: Yup.string().nullable()
              .test('phone', 'Numero di cellulare o email richiesti', function(value, context) {
                if (context.parent.email) {
                  return true;
                }

                return value && value.length > 0;
              }),
            email: Yup.string().email('Inserisci un indirizzo email valido').nullable().test('email', 'Email o numero di cellulare richiesti', function(value, context) {
              if (context?.parent?.phone) {
                return true;
              }

              return value && value.length > 0;
            })
          })}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ errors, handleSubmit, touched, values, submitForm, setFieldValue }) => (
            <form onSubmit={handleSubmit} id={'appointment-form'}>
              <UIModalHeader>
                <UIPageHeader
                  title={'Aggiungi nuovo paziente'}
                  sx={{
                    textAlign: 'center',
                    marginTop: '0px'
                  }}
                />
              </UIModalHeader>
              <UIModalBody>

                <UIFormControlBox>
                  <UIPageHeader
                    title={'Dati richiesti'}
                    subtitle={
                      'Nome e cognome sono obbligatori. Inserisci almeno un tra numero di cellulare ed indirizzo email.'
                    }
                    sx={{
                      textAlign: 'center',
                      marginTop: '0px',
                      padding: '0px'
                    }}
                    sxSubtitle={{
                      textAlign: 'center',
                      marginX: '2px'
                    }}
                  />
                </UIFormControlBox>


                <UIFormControlBox label={'Nome'} error={Boolean(touched.name && errors.name)}
                                  error_message={errors?.name}>
                  <UIInput value={values.name} onChange={(e) => setFieldValue('name', e.target.value)} />
                </UIFormControlBox>

                <UIFormControlBox label={'Cognome'} error={Boolean(touched.surname && errors.surname)}
                                  error_message={errors?.surname}>
                  <UIInput value={values.surname} onChange={(e) => setFieldValue('surname', e.target.value)} />
                </UIFormControlBox>

                <UIFormControlBox label={'Telefono'} error={Boolean(touched.phone && errors.phone)}
                                  error_message={errors?.phone}>
                  <UIInput value={values.phone} onChange={(e) => setFieldValue('phone', e.target.value)} />
                </UIFormControlBox>

                <UIFormControlBox label={'Email'} error={Boolean(touched.email && errors.email)}
                                  error_message={errors?.email}>
                  <UIInput value={values.email} onChange={(e) => setFieldValue('email', e.target.value)} />
                </UIFormControlBox>

              </UIModalBody>
              <UIModalButtons>
                <UIButton type={'submit'} onClick={() => submitForm()}
                          icon={<IconCheck stroke={'1.64'} size={'26px'} />} />
              </UIModalButtons>
            </form>
          )}
        </Formik>
      </UiModalInternal>
    </UIModal>
  );
};

export default ModalAddPatient;
