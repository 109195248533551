import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { UIModal, UIModalBody, UIModalButtons, UIModalHeader, UiModalInternal } from '../../utilities/Modal';
import { UICheckbox, UIInput } from '../../utilities/Input';
import { UIFormControlBox } from '../../utilities/Form';
import { UIButton } from '../../utilities/Button';
import { IconCheck, IconTrash } from '@tabler/icons-react';
import UITypography from '../../utilities/Typography';
import { UiDialog } from '../../utilities/Dialog';
import { useSettings } from '../../../hooks/Settings/useSettings';

const ModalEdit = ({ modalOpen, handleModalClose, warning }) => {
  let [warningData, setWarningData] = useState(null);
  const [deleteMode, setDeleteMode] = useState(false);

  const { onGetWarning, onCreateWarning, onUpdateWarning, onDeleteWarning } = useSettings();

  const getWarning = async () => {
    onGetWarning({
      warning_uuid: warning?.uuid
    }).then((res) => {
      setWarningData(res);
    });
  };

  useEffect(() => {
    if (!warning) return;
    getWarning().then(() => {
    });
  }, [warning]);

  const handleSubmit = async (values) => {
    const { text, warning_icon, active } = values;

    if (warningData?.uuid) {
      onUpdateWarning({
        warning_uuid: warningData?.uuid,
        warning_data: {
          text,
          warning_icon,
          active
        }
      }).then((res) => {
        toast(res?.response, {
          style: {
            fontSize: '14px',
            backgroundColor: res?.responseStatus ? '#00e676' : '#FF646C',
            color: '#ffffff'
          }
        });
        if (res?.responseStatus) {
          handleModalCloseInternal();
        }
      });
    } else {
      onCreateWarning({
        warning_data: {
          text,
          warning_icon,
          active
        }
      }).then((res) => {
        toast(res?.response, {
          style: {
            fontSize: '14px',
            backgroundColor: res?.responseStatus ? '#00e676' : '#FF646C',
            color: '#ffffff'
          }
        });
        if (res?.responseStatus) {
          handleModalCloseInternal();
        }
      });
    }
  };

  const handleDeleteWarning = () => {
    onDeleteWarning({
      warning_uuid: warning?.uuid
    }).then((res) => {
      if (res?.response) {
        toast(res?.response, {
          style: {
            fontSize: '14px',
            backgroundColor: '#00e676',
            color: '#ffffff'
          }
        });
      }
      handleModalCloseInternal();
    });
  };

  const handleModalCloseInternal = () => {
    setWarningData(null);
    setDeleteMode(false);
    handleModalClose();
  };

  return (
    <UIModal open={modalOpen} onClose={handleModalCloseInternal} allowFullScreen={true} className={'modal'}>

      <UiDialog
        type={'default'}
        variant={'cancel'}
        title={'Vuoi eliminare definitivamente l\'avviso?'}
        open={deleteMode}
        onClose={() => setDeleteMode(false)}
        onConfirm={handleDeleteWarning}
      />

      <Formik
        initialValues={{
          text: warningData?.text || '',
          warning_icon: warningData?.warning_icon || false,
          active: warningData?.active
        }}
        validationSchema={Yup.object().shape({
          text: Yup.string().required('Il campo è obbligatorio'),
          warning_icon: Yup.boolean(),
          active: Yup.boolean()
        })}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ errors, submitForm, handleSubmit, touched, values, setFieldValue }) => (
          <form onSubmit={handleSubmit} id={'warning-form'}>
            <UiModalInternal onClose={handleModalCloseInternal} isLoading={warning && warningData === null}
                             containerSx={{
                               width: '80%',
                               maxWidth: '800px'
                             }}>
              <UIModalHeader sx={{ border: 'none', p: '50px 0 0 0' }} />
              <UIModalBody
                sx={{
                  bgcolor: 'transparent'
                }}
              >

                <UIFormControlBox label={'Testo'} error={Boolean(touched.text && errors.text)}
                                  error_message={errors?.text}>
                  <UIInput value={values.text} onChange={(e) => setFieldValue('text', e.target.value)} />
                </UIFormControlBox>

                <UIFormControlBox>
                  <UICheckbox
                    label={<UITypography title={'Icona presente'} />}
                    name={'warning_icon'}
                    checked={values?.warning_icon}
                    onChange={(e) => setFieldValue('warning_icon', e.target.checked)}
                  />
                </UIFormControlBox>

                <UIFormControlBox>
                  <UICheckbox
                    label={<UITypography title={'Attivo'} />}
                    name={'active'}
                    readOnly={true}
                    checked={values?.active}
                    onChange={(e) => setFieldValue('active', e.target.checked)}
                  />
                </UIFormControlBox>


              </UIModalBody>


              <UIModalButtons>
                <UIButton type={'submit'} onClick={() => submitForm()}
                          icon={<IconCheck stroke={'1.64'} size={'26px'} />} />
                <UIButton
                  type={'submit'}
                  onClick={() => {
                    setDeleteMode(true);
                  }}
                  icon={<IconTrash stroke={1.64} size="25px" />}
                  sxCustom={{
                    bgcolor: '#FF646C'
                  }}
                />
              </UIModalButtons>
            </UiModalInternal>

          </form>
        )}
      </Formik>
    </UIModal>
  );
};

export default ModalEdit;


