import { IconCheck, IconTrash } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { UIModal, UIModalBody, UIModalButtons, UIModalHeader, UiModalInternal } from '../../utilities/Modal';
import UITypography from '../../utilities/Typography';
import { UICheckbox, UIInput } from '../../utilities/Input';
import { UIFormControlBox } from '../../utilities/Form';
import { UIButton } from '../../utilities/Button';

const ModalEditVariant = ({ modalOpen, handleModalClose, variant, variants, setFieldValue }) => {
  let [variantData, setVariantData] = useState(null);
  let [variantsData, setVariantsData] = useState(null);

  useEffect(() => {
      setVariantData(variant ?? null);
      setVariantsData(variants ?? null);
  }, [variants,  variant]);

  const handleSubmit = async (values) => {
    const { name, size, price, quantity, active } = values;

    if (variantData?.uuid) {
      variantsData = variantsData?.map((item) => {
        if (item.uuid === variantData.uuid) {
          return {
            ...item,
            name,
            size,
            price,
            quantity,
            active
          };
        } else {
          return item;
        }
      });
      setFieldValue('variants', variantsData);
    } else {
      let new_array = [...variantsData];
      new_array?.push({
        uuid: uuidv4(),
        name,
        size,
        price,
        quantity,
        active,
        newItem: true
      });
      setFieldValue('variants', new_array);
    }

    handleModalCloseInternal();
  };

  const handleModalCloseInternal = () => {
    handleModalClose();
  };
  return (
    <UIModal open={modalOpen} onClose={handleModalCloseInternal} allowFullScreen={true} className={'modal'}>

      <Formik
            initialValues={{
              name: variantData?.name ?? '',
              size: variantData?.size ?? '',
              price: variantData?.price ?? '',
              quantity: variantData?.quantity ?? '',
              active: variantData?.active ?? false
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Il nome è obbligatorio'),
              size: Yup.string().required('La taglia è obbligatoria'),
              price: Yup.number().required('Il prezzo è obbligatorio'),
              quantity: Yup.number().required('La quantità è obbligatoria'),
              active: Yup.boolean().nullable()
            })}
            onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
            enableReinitialize={true}
          >
        {({ errors, submitForm, handleSubmit, touched, values, setFieldValue }) => (
              <form onSubmit={handleSubmit} id={'appointment-form'}>
                <UiModalInternal onClose={handleModalCloseInternal} isLoading={variant && variantData === null}
                                 containerSx={{
                                   width: '80%',
                                   maxWidth: '800px'
                                 }}>
                  <UIModalHeader sx={{ border: 'none', p: '50px 0 0 0' }}>
                    <UITypography
                      variant={'Oxygen700'}
                      title={`Dati variante`}
                      size={'18px'}
                      sxCustom={{
                        textAlign: 'center',
                        color: '#101828'
                      }}
                    />

                  </UIModalHeader>
                  <UIModalBody
                    sx={{
                      bgcolor: 'transparent'
                    }}
                  >


                    <UIFormControlBox label={'Nome'} error={Boolean(touched.name && errors.name)}
                                      error_message={errors?.name}>
                      <UIInput value={values?.name} onChange={(e) => setFieldValue('name', e.target.value)} />
                    </UIFormControlBox>

                    <UIFormControlBox label={'Taglia'} error={Boolean(touched.size && errors.size)}
                                      error_message={errors?.size}>
                      <UIInput value={values?.size} onChange={(e) => setFieldValue('size', e.target.value)} />
                    </UIFormControlBox>

                    <UIFormControlBox label={'Quantità'} error={Boolean(touched.quantity && errors.quantity)}
                                      error_message={errors?.quantity}>
                      <UIInput value={values?.quantity} onChange={(e) => setFieldValue('quantity', e.target.value)} />
                    </UIFormControlBox>

                    <UIFormControlBox label={'Prezzo'} error={Boolean(touched.price && errors.price)}
                                      error_message={errors?.price}>
                      <UIInput value={values?.price} onChange={(e) => setFieldValue('price', e.target.value)}
                               type="number"
                               inputProps={{
                                 step: 0.01,
                                 min: 0,
                                 lang: 'en-150'
                               }} />
                    </UIFormControlBox>

                    <UIFormControlBox>
                      <UICheckbox
                        label={<UITypography title={'Attivo'} />}
                        name={'active'}
                        readOnly={true}
                        checked={values?.active}
                        onChange={(e) => setFieldValue('active', e.target.checked)}
                      />
                    </UIFormControlBox>


                  </UIModalBody>
                  <UIModalButtons>
                    <UIButton type={'submit'} onClick={() => submitForm()}
                              icon={<IconCheck stroke={'1.64'} size={'26px'} />} />
                    <UIButton
                      type={'submit'}
                      onClick={() => {
                        setDeleteMode(true);
                      }}
                      icon={<IconTrash stroke={1.64} size="25px" />}
                      sxCustom={{
                        bgcolor: '#FF646C'
                      }}
                    />
                  </UIModalButtons>
                </UiModalInternal>
              </form>
            )}
          </Formik>
    </UIModal>
  );
};

export default ModalEditVariant;
