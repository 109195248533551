import gql from 'graphql-tag';

export const GET_WARNING = gql`
  query getWarning($warning_uuid: ID!) {
    adminRoute {
      getWarning(warning_uuid:  $warning_uuid) {
        id
        uuid
        text
        warning_icon
        active
      }
    }
  }
`;

export const LIST_WARNINGS_ADMIN_PAGINATED = gql`
  query listWarningsAdminPaginated($filters: mixedFilters) {
    adminRoute {
      listWarningsAdminPaginated(filters:$filters) {
        count
        rows {
          id
          uuid
          text
          warning_icon
          active
        }
      }
    }
  }
`;

export const CREATE_WARNING = gql`
  query createWarning($warning_data: WarningInput!) {
    adminRoute {
      createWarning(warning_data: $warning_data) {
       responseStatus
        response
      }
    }
  }
`;

export const UPDATE_WARNING = gql`
  query updateWarning($warning_uuid: ID!, $warning_data: WarningInput!) {
    adminRoute {
      updateWarning(warning_uuid: $warning_uuid, warning_data: $warning_data) {
        responseStatus
        response
      }
    }
  }
`;

export const DELETE_WARNING = gql`
  query deleteWarning($warning_uuid: ID!) {
    adminRoute {
      deleteWarning(warning_uuid: $warning_uuid) {
        responseStatus
        response
      }
    }
  }
`;
