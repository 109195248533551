import { Box } from '@chakra-ui/react';
import {
  Chip,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { IconCheck, IconTrash } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useShop } from '../../../hooks/Shop/useShop';
import Paper from '@mui/material/Paper';
import { UIModal, UIModalBody, UIModalButtons, UIModalHeader, UiModalInternal } from '../../utilities/Modal';
import { UiDialog } from '../../utilities/Dialog';
import { UICheckbox, UIInput } from '../../utilities/Input';
import { UIFormControlBox } from '../../utilities/Form';
import UITypography from '../../utilities/Typography';
import { UIButton } from '../../utilities/Button';
import { UIPageTitle } from '../../utilities/Headers';
import { DateTimePicker } from '@mui/x-date-pickers';
import moment from 'moment/moment';

const types = ['function', 'ingredient', 'protocol', 'brand'];

const ModalEdit = ({ modalOpen, handleModalClose, discount }) => {
  let [deleteMode, setDeleteMode] = useState(false);
  let [data, setData] = useState({});
  let [categories, setCategories] = useState(null);
  let [listBrand, setListBrand] = useState([]);
  let [listProtocol, setListProtocol] = useState([]);
  let [listIngredient, setListIngredient] = useState([]);
  let [listFunction, setListFunction] = useState([]);
  let [products, setProducts] = useState([]);
  let [listSelectedProducts, setListSelectedProducts] = useState([]);

  const {
    onGetShopDiscount,
    onCreateShopDiscount,
    onUpdateShopDiscount,
    onDeleteShopDiscount,
    onListShopCategories,
    onListShopProductsByQuery
  } = useShop();

  const getDiscount = async () => {
    onGetShopDiscount({
      discount_uuid: discount?.uuid
    }).then((res) => {
      let categories = [];
      res?.categories?.forEach((category) => {
        if (!categories[category?.type]) {
          categories[category?.type] = [];
        }
        categories?.[category?.type]?.push(category.uuid);
      });

      setListSelectedProducts(res?.products);

      res.categories = categories;
      res.products = res?.products?.map((product) => product?.uuid);

      setDeleteMode(false);
      setData(res);
    });
  };

  const listCategories = async () => {
    onListShopCategories().then((res) => {
      let new_res = {};

      res?.forEach((category) => {
        if (new_res[category.type]) {
          new_res?.[category.type]?.push(category);
        } else {
          new_res[category.type] = [category];
        }
      });

      setCategories(new_res);
    });
  };

  const listProducts = async () => {
    onListShopProductsByQuery({ forceAll: true }).then((res) => {
      setProducts(res);
    });
  };

  const createInitList = () => {
    let list = [];
    for (let type of types) {
      data?.categories?.[type]?.forEach((value) => {
        let category = categories?.[type]?.find((category) => category?.uuid === value);

        if (category) {
          if (!list[category?.type]) {
            list[category?.type] = [];
          }
          list?.[category?.type]?.push({
            uuid: category?.uuid,
            name: category?.name,
            discount_value: category?.discount_value ?? 0,
            discount_type: 'percentage'
          });
        }
      });
    }

    setListBrand(list?.brand ?? []);
    setListProtocol(list?.protocol ?? []);
    setListIngredient(list?.ingredient ?? []);
    setListFunction(list?.function ?? []);
  };

  useEffect(() => {
    if (discount?.uuid) {
      getDiscount();
    } else {
      setData(null);
    }
  }, [discount]);

  useEffect(() => {
    listCategories();
    listProducts();
  }, [data]);

  useEffect(() => {
    createInitList();
  }, [categories]);

  const handleDeleteDiscount = async () => {
    await onDeleteShopDiscount({
      discount_uuid: data?.uuid
    });
    setDeleteMode(false);
    handleModalClose();
  };

  const handleSubmit = async (values) => {
    const { title, start_date, end_date, active } = values;

    let product_final = listSelectedProducts?.map((product) => {
      return {
        uuid: product.uuid,
        deleteItem: product.deleteItem,
        variants: product?.variants?.map((variant) => {
          return {
            uuid: variant.uuid,
            discount_price: variant.discount_price
          };
        })
      };
    });

    if (data?.uuid) {
      onUpdateShopDiscount({
        discount_uuid: data?.uuid,
        discount_data: {
          title,
          start_date,
          end_date,
          categories: [...listProtocol, ...listIngredient, ...listFunction, ...listBrand],
          products: product_final,
          active
        }
      }).then((res) => {
        if (res?.response) {
          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          handleModalClose();
        }
      });
    } else {
      onCreateShopDiscount({
        discount_data: {
          title,
          start_date,
          end_date,
          categories: [...listProtocol, ...listIngredient, ...listFunction, ...listBrand],
          products: product_final,
          active
        }
      }).then((res) => {
        if (res?.response) {
          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          handleModalClose();
        }
      });
    }
  };

  const getCategoriesByType = (type) => {
    switch (type) {
      case 'brand':
        return listBrand;
      case 'protocol':
        return listProtocol;
      case 'ingredient':
        return listIngredient;
      case 'function':
        return listFunction;
      default:
        break;
    }
  };

  const handleSelectCategory = (value, type) => {
    let new_value = [...getCategoriesByType(type)];

    for (let item of value) {
      if (!new_value?.find((element) => element?.uuid === item)) {
        let category = categories?.[type]?.find((category) => category?.uuid === item);

        if (category) {
          new_value?.push({
            uuid: category?.uuid,
            name: category?.name,
            discount_value: category?.discount_value ?? 0,
            discount_type: 'percentage'
          });
        }
      }
    }

    for (let item of Object.keys(new_value)) {
      new_value[item].deleteItem = !value?.includes(new_value[item]?.uuid);
    }

    switch (type) {
      case 'brand':
        setListBrand(new_value);
        break;
      case 'protocol':
        setListProtocol(new_value);
        break;
      case 'ingredient':
        setListIngredient(new_value);
        break;
      case 'function':
        setListFunction(new_value);
        break;
    }
  };

  const handleSelectProduct = (value) => {
    let new_value = [...listSelectedProducts];

    for (let item of value) {
      if (!new_value?.find((element) => element?.uuid === item)) {
        let product = products?.find((product) => product?.uuid === item);

        if (product) {
          new_value?.push({
            uuid: product?.uuid,
            name: product?.name,
            discount_value: product?.discount_value ?? 0,
            discount_type: 'percentage',
            variants: product?.variants
          });
        }
      }
    }

    for (let item of Object.keys(new_value)) {
      new_value[item].deleteItem = !value?.includes(new_value[item]?.uuid);
    }

    setListSelectedProducts(new_value);
  };

  const handleDeleteProduct = (value) => {
    let new_value = [...listSelectedProducts];

    let element_key = new_value?.findIndex((element) => element?.uuid === value);

    if (element_key >= 0) {
      new_value[element_key].deleteItem = true;
    }

    setListSelectedProducts(new_value);
  };

  const handleSetListValue = (value, category, type) => {
    let new_value = [...getCategoriesByType(type)];

    let element_key = new_value?.findIndex((element) => element?.uuid === category?.uuid);

    if (element_key >= 0) {
      new_value[element_key].discount_value = Number(value);
    } else {
      new_value?.push({
        uuid: category?.uuid,
        name: category?.name,
        discount_value: Number(value),
        discount_type: 'percentage'
      });
    }

    switch (type) {
      case 'brand':
        setListBrand(new_value);
        break;
      case 'protocol':
        setListProtocol(new_value);
        break;
      case 'ingredient':
        setListIngredient(new_value);
        break;
      case 'function':
        setListFunction(new_value);
        break;
    }
  };

  const handleSetListProductValue = (value, product, variant) => {
    let new_value = [...listSelectedProducts];

    let productIndex = new_value?.findIndex((element) => element?.uuid === product?.uuid);
    let variantIndex = new_value[productIndex]?.variants?.findIndex((element) => element?.uuid === variant?.uuid);
    new_value[productIndex].variants[variantIndex].discount = true;
    new_value[productIndex].variants[variantIndex].discount_price = Number(value);

    setListSelectedProducts(new_value);
  };

  const handleDeleteCategory = ({ value, type }) => {
    let new_value = [...getCategoriesByType(type)];

    let element_key = new_value?.findIndex((element) => element?.uuid === value);

    if (element_key >= 0) {
      new_value[element_key] = {
        ...new_value[element_key],
        deleteItem: true
      };

      switch (type) {
        case 'brand':
          setListBrand(new_value);
          break;
        case 'protocol':
          setListProtocol(new_value);
          break;
        case 'ingredient':
          setListIngredient(new_value);
          break;
        case 'function':
          setListFunction(new_value);
          break;
      }
    }
  };

  const handleModalCloseInternal = () => {
    setData(null);
    setDeleteMode(false);
    handleModalClose();
  };

  return (
    <UIModal open={modalOpen} onClose={handleModalCloseInternal} allowFullScreen={true} className={'modal'}>

      <UiDialog
        type={'default'}
        variant={'cancel'}
        title={'Vuoi eliminare definitivamente lo sconto?'}
        open={deleteMode}
        onClose={() => setDeleteMode(false)}
        onConfirm={handleDeleteDiscount}
      />
          <Formik
            initialValues={{
              title: data?.title ?? '',
              categories_function: data?.categories?.function ?? [],
              categories_protocol: data?.categories?.protocol ?? [],
              categories_ingredient: data?.categories?.ingredient ?? [],
              categories_brand: data?.categories?.brand ?? [],
              products: data?.products ?? [],
              start_date: data?.start_date ? moment(Number(data?.start_date)) : null,
              end_date: data?.end_date ? moment(Number(data?.end_date)) : null,
              active: data?.active ?? false
            }}
            validationSchema={Yup.object().shape({
              title: Yup.string().required('Il nome sconto è obbligatorio'),
              categories_function: Yup.array().nullable(),
              categories_protocol: Yup.array().nullable(),
              categories_ingredient: Yup.array().nullable(),
              categories_brand: Yup.array().nullable(),
              products: Yup.array().nullable(),
              active: Yup.boolean().nullable()
            })}
            onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
            enableReinitialize={true}
          >
            {({ errors, handleSubmit, touched, values, setFieldValue }) => (
              <form onSubmit={handleSubmit} id={'appointment-form'}>
                <UiModalInternal onClose={handleModalCloseInternal} isLoading={discount && data === null} containerSx={{
                  width: '80%',
                  maxWidth: '800px'
                }}>
                  <UIModalHeader sx={{ border: 'none', p: '20px 0 0 0' }}>
                    <UIPageTitle title={(discount) ? 'Modifica sconto' : 'Aggiungi sconto'} sx={{
                      textAlign: 'center',
                      color: '#344054',
                    }} />
                  </UIModalHeader>
                  <UIModalBody
                    sx={{
                      bgcolor: 'transparent'
                    }}
                  >

                    <UIFormControlBox label={'Titolo'} error={Boolean(touched.title && errors.title)}
                                      error_message={errors?.title}>
                      <UIInput value={values.title} onChange={(e) => setFieldValue('title', e.target.value)} />
                    </UIFormControlBox>

                      {types?.map((type, index) => (
                        <SelectCategoryType
                          key={index}
                          type={type}
                          categories={categories}
                          errors={errors}
                          touched={touched}
                          values={values}
                          setFieldValue={setFieldValue}
                          selectedCategories={getCategoriesByType(type)}
                          handleSelectCategory={handleSelectCategory}
                          handleSetListValue={handleSetListValue}
                          handleDeleteCategory={handleDeleteCategory}
                        />
                      ))}

                      <SelectProducts
                        products={products}
                        errors={errors}
                        touched={touched}
                        values={values?.products}
                        setFieldValue={setFieldValue}
                        selectedProducts={listSelectedProducts}
                        handleSelectProduct={handleSelectProduct}
                        handleSetListProductValue={handleSetListProductValue}
                        handleDeleteProduct={handleDeleteProduct}
                      />

                    <UIFormControlBox label={'Data di inizio sconto'}
                                      error={Boolean(touched.start_date && errors.start_date)}>
                      <DateTimePicker
                        disablePast={true}
                        value={values?.start_date}
                        locale={'it'}
                        onChange={(res) => {
                          setFieldValue('start_date', res);
                        }} />
                    </UIFormControlBox>

                    <UIFormControlBox label={'Data di fine sconto'}
                                      error={Boolean(touched.end_date && errors.end_date)}>
                      <DateTimePicker
                        disablePast={true}
                        value={values?.end_date}
                        locale={'it'}
                        onChange={(res) => {
                          setFieldValue('end_date', res);
                        }} />
                    </UIFormControlBox>

                    <UIFormControlBox>
                      <UICheckbox
                        label={<UITypography title={'Attivo'} />}
                        name={'active'}
                        readOnly={true}
                        checked={values?.active}
                        onChange={(e) => setFieldValue('active', e.target.checked)}
                      />
                    </UIFormControlBox>
                  </UIModalBody>
                  <UIModalButtons>
                    <UIButton type={'submit'} onClick={() => handleSubmit()}
                              icon={<IconCheck stroke={'1.64'} size={'26px'} />} />
                    <UIButton
                      type={'submit'}
                      onClick={() => {
                        setDeleteMode(true);
                      }}
                      icon={<IconTrash stroke={1.64} size="25px" />}
                      sxCustom={{
                        bgcolor: '#FF646C'
                      }}
                    />
                  </UIModalButtons>
                </UiModalInternal>
              </form>
            )}
          </Formik>
    </UIModal>
  );
};

const SelectProducts = ({
  index,
  type,
  products,
  touched,
  errors,
  values,
  setFieldValue,
  selectedProducts,
  handleSelectProduct,
  handleSetListProductValue,
  handleDeleteProduct
}) => {
  return (
    <Box mt={5} alignItems={'center'} key={index}>
      <UIFormControlBox label={'Singoli prodotti'} error={Boolean(touched.categories && errors.categories)}
                        error_message={errors?.title}>
          <Select
            variant={'filled'}
            labelId="products-label"
            id="products-select"
            multiple={true}
            value={values}
            onChange={(event) => {
              handleSelectProduct(event?.target?.value);
              setFieldValue('products', event?.target?.value);
            }}
            input={<OutlinedInput id="categories-input" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected?.map((value, index) => (
                  <ChipCustomProducts
                    key={index}
                    type={type}
                    value={value}
                    products={products}
                    handleDeleteProduct={handleDeleteProduct}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </Box>
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 48 * 4.5 + 8,
                  width: '100%'
                }
              }
            }}
          >
            {products?.map((product, index) => (
              <MenuItem key={index} value={product?.uuid}>
                {product?.name}
              </MenuItem>
            ))}
          </Select>

      </UIFormControlBox>

        <SelectedProductsList
          selectedProducts={selectedProducts}
          products={products}
          handleSetListProductValue={handleSetListProductValue}
        />
    </Box>
  );
};

const SelectCategoryType = ({
  index,
  type,
  categories,
  touched,
  errors,
  values,
  setFieldValue,
  selectedCategories,
  handleSelectCategory,
  handleSetListValue,
  handleDeleteCategory
}) => {
  let [title, setTitle] = useState('');

  useEffect(() => {
    switch (type) {
      case 'function':
        setTitle('Funzioni');
        break;
      case 'protocol':
        setTitle('Protocolli');
        break;
      case 'ingredient':
        setTitle('Ingredienti');
        break;
      case 'brand':
        setTitle('Marca');
        break;
      default:
        setTitle('Categoria');
        break;
    }
  }, [type, categories]);

  const disabledControl = (category) => {
    let item = selectedCategories?.find((item) => item?.uuid === category?.uuid);

    return Boolean(!item && category?.discounts?.length);
  };

  return (
    <Box mt={5} key={index}>

      <UIFormControlBox label={title} error={Boolean(touched.categories && errors.categories)}
                        error_message={errors?.title}>

          <Select
            labelId="categories-label"
            id="categories-select"
            multiple={true}
            value={values['categories_' + type]}
            onChange={(event) => {
              handleSelectCategory(event?.target?.value, type);
              setFieldValue('categories_' + type, event.target.value);
            }}
            input={<OutlinedInput id="categories-input" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected?.map((value, index) => (
                  <ChipCustom
                    key={index}
                    type={type}
                    value={value}
                    categories={categories}
                    handleDeleteCategory={handleDeleteCategory}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </Box>
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 48 * 4.5 + 8,
                  width: 250
                }
              }
            }}
            variant={'filled'}>
            {categories?.[type]?.map((category, index) => {
              let disabled = disabledControl(category);

              return (
                <MenuItem key={index} value={category?.uuid} disabled={disabled}>
                  {category?.name} {disabled && ' - Sconto già applicato'}
                </MenuItem>
              );
            })}
          </Select>
      </UIFormControlBox>

        <SelectedCategoriesList
          selectedCategories={selectedCategories}
          type={type}
          categories={categories}
          handleSetListValue={handleSetListValue}
        />
    </Box>
  );
};

const ChipCustomProducts = ({ products, value, type }) => {
  let [label, setLabel] = useState('');

  useEffect(() => {
    products?.forEach((product) => {
      if (product?.uuid === value) setLabel(product?.name);
    });
  }, [value, products, type]);

  if (!label) return <></>;
  return (
    <Chip
      key={value}
      label={label}
      // clickable
      sx={{
        ml: 0.5
      }}
    />
  );
};

const ChipCustom = ({ categories, value, type }) => {
  let [label, setLabel] = useState('');

  useEffect(() => {
    categories?.[type]?.forEach((category) => {
      if (category?.uuid === value) setLabel(category?.name);
    });
  }, [value, categories, type]);

  if (!label) return <></>;
  return (
    <Chip
      key={value}
      label={label}
      clickable
      sx={{
        ml: 0.5
      }}
    />
  );
};

const SelectedCategoriesList = ({ selectedCategories, type, handleSetListValue }) => {
  return (
    <>
      {selectedCategories?.some((el) => !el.deleteItem) && (
        <Box display={'flex'} mt={5} p={'10px'} alignItems={'center'}>
          <Box ml={10} w={'85%'}>
            <TableContainer
              component={Paper}
              sx={{
                width: '100%'
              }}
            >
              <Table sx={{ width: '100%' }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Categoria</TableCell>
                    <TableCell align="center">Percentuale di sconto</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedCategories?.map((category, index) => {
                    if (category?.deleteItem) return <React.Fragment key={index}></React.Fragment>;

                    return (
                      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row" align={'center'}>
                          {category.name}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <OutlinedInput
                            id={'discount_value_' + category?.uuid}
                            sx={{
                              overflow: 'hidden',
                              width: '100px'
                            }}
                            type="number"
                            value={category?.discount_value}
                            name="discount_value"
                            onChange={(e) => {
                              handleSetListValue(e.target.value, category, type);
                            }}
                            label={''}
                            inputProps={{
                              step: 0.1,
                              min: 0,
                              lang: 'en-150'
                            }}
                          />
                          <Typography ml={2} variant={'h3'}>
                            %
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      )}
    </>
  );
};

const SelectedProductsList = ({ selectedProducts, handleSetListProductValue }) => {
  return (
    <>
      {selectedProducts?.some((el) => !el.deleteItem) && (
        <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
          <Box ml={10} w={'85%'}>
            <TableContainer
              component={Paper}
              sx={{
                width: '100%'
              }}
            >
              <Table sx={{ width: '100%' }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Prodotto</TableCell>
                    <TableCell align="center">Prezzo</TableCell>
                    <TableCell align="center">Prezzo scontato</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedProducts?.map((product, index) => {
                    if (product?.deleteItem) return <React.Fragment key={index}></React.Fragment>;

                    return (
                      <>
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell
                            component="th"
                            scope="row"
                            align={'center'}
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '1.2rem',
                              backgroundColor: 'lightgrey',
                              color: 'white'
                            }}
                          >
                            {product.name}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align={'center'}
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '1.2rem',
                              backgroundColor: 'lightgrey',
                              color: 'white'
                            }}
                          />
                          <TableCell
                            component="th"
                            scope="row"
                            align={'center'}
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '1.2rem',
                              backgroundColor: 'lightgrey',
                              color: 'white'
                            }}
                          />
                        </TableRow>

                        {product?.variants?.map((variant, index) => (
                          <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row" align={'center'}>
                              {variant?.name} - {variant?.size}
                            </TableCell>
                            <TableCell component="th" scope="row" align={'center'}>
                              {variant.price} €
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <OutlinedInput
                                id={'product_' + product?.uuid}
                                sx={{
                                  overflow: 'hidden',
                                  width: '100px'
                                }}
                                type="number"
                                value={variant?.discount ? variant?.discount_price : ''}
                                name="discount_price"
                                onChange={(e) => {
                                  handleSetListProductValue(e.target.value, product, variant);
                                }}
                                label={''}
                                inputProps={{
                                  step: 0.01,
                                  min: 0,
                                  lang: 'en-150'
                                }}
                              />
                              <Typography ml={2} variant={'h3'}>
                                €
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ModalEdit;
