import React, { useCallback, useEffect, useState } from 'react';
import { Box, Link, Typography } from '@mui/material';
import './custom-doctors.css';
import { IconArrowsSort, IconCheckbox, IconEdit, IconPhone } from '@tabler/icons-react';
import { useAccounts } from '../../../hooks/Accounts/useAccounts';
import ModalUserPicsDoctor from './ModalUserPicsDoctor';
import ModalUserDetails from '../modals/modalUserDetails';
import UITable from '../../utilities/Table';
import { SectionHeader, UIPageHeader } from '../../utilities/Headers';
import { UIButton } from '../../utilities/Button';
import { UiDialog } from '../../utilities/Dialog';
import { toast } from 'react-toastify';
import { useOffices } from '../../../hooks/Offices/useOffices';
import { useTreatments } from '../../../hooks/Treatments/useTreatments';
import { useAuthentication } from '../../../utils/useAuthentication';
import UITypography from '../../utilities/Typography';
import ModalAddPatient from '../modals/modalAddPatient';

const UsersView = () => {
  const data = useAuthentication();
  const user = data?.user?.account_data;
  const [selectedUser, setSelectedUser] = useState(null);
  const [treatments, setTreatments] = useState([]);
  const [offices, setOffices] = useState([]);
  const [viewMode, setViewMode] = useState(false);
  const [picsMode, setPicsMode] = useState(false);
  const [phoneMode, setPhoneMode] = useState(false);
  const [treatmentsMode, setTreatmentsMode] = useState(false);
  const [officeMode, setOfficeMode] = useState(false);
  const [addPatientMode, setAddPatientMode] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [filtersData, setFiltersData] = useState({
    order: null,
    treatment_ids: [],
    office_id: null
  });

  const { onListUsersAdminPaginated } = useAccounts();
  const { onListOffices } = useOffices();
  const { onListTreatmentsAdmin, onListTreatmentsDoctor } = useTreatments();

  useEffect(() => {
    if (user.admin) {
      onListTreatmentsAdmin().then((res) => {
        setTreatments(res);
      });
    } else {
      onListTreatmentsDoctor().then((res) => {
        setTreatments(res);
      });
    }
    onListOffices().then((res) => {
      setOffices(res);
    });
  }, []);

  const handleModalClose = useCallback(() => {
    setViewMode(false);
    setPicsMode(false);
    setSelectedUser(null);
    setForceUpdate(true);
  }, []);

  const prepareValues = (users) =>
    users?.map((user) => {
      return {
        patient: user,
        patientEl: (
          <Box>
            <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
              {user?.name} {user?.surname}
            </Typography>
            <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'13px'} color={'#667085'}>
              <Link
                href={`mailto:${user?.email}`}
                target={'_blank'}
                rel={'noreferrer'}
                component={'a'}
                underline={'always'}
                color={'#667085'}
              >
                {user?.email}
              </Link>
            </Typography>
            <Typography fontFamily={'Oxygen'} fontWeight={'400'} fontSize={'13px'}>
              <Link
                href={`tel:${user?.phone_country?.dial_code}${user?.phone}`}
                target={'_blank'}
                rel={'noreferrer'}
                component={'a'}
                underline={'always'}
                color={'#667085'}
              >
                {user?.phone_country?.dial_code} {user?.phone}
              </Link>
            </Typography>
          </Box>
        ),
        onBoardingEl: (
          <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            {!user?.first_login && <IconCheckbox />}
          </Box>
        ),
        actionsEl: (
          <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'flex-end'} mr={'15px'}>
            <UIButton
              type={'icon'}
              onClick={() => {
                setSelectedUser(user);
                setPhoneMode(true);
              }}
              icon={
                <IconPhone
                  stroke={'1.67px'}
                  style={{
                    width: '24px'
                  }}
                />
              }
            />
            <UIButton
              type={'icon'}
              onClick={() => {
                setSelectedUser(user);
                setViewMode(true);
              }}
              icon={
                <IconEdit
                  stroke={'1.67px'}
                  style={{
                    width: '24px'
                  }}
                />
              }
            />
          </Box>
        )
      };
    });

  const filtersButtons = (

    <Box
      sx={{
        display: 'flex',
        justifyContent: 'between',
        alignItems: 'center',
        width: '100%',
        gap: '10px'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
          gap: '10px'
        }}>
        <Box width={'120px'}>
          <UIButton
            icon={
              <IconArrowsSort
                stroke={'1.64'}
                size={'20px'}
                style={{
                  marginRight: 5,
                  width: '20px'
                }}
              />
            }
            checked={filtersData?.order !== null}
            label={'ABC'}
            type={'filter'}
            onClick={() => {
              setFiltersData({
                ...filtersData,
                order: filtersData?.order === 'ASC' ? null : 'ASC'
              });
            }}
          />
        </Box>
        <Box width={'120px'}>
          <UIButton
            checked={filtersData?.treatment_ids?.length > 0}
            label={'Trattamento'}
            type={'filter'}
            onClick={() => {
              setTreatmentsMode(true);
            }}
          />
        </Box>
        <Box width={'120px'}>
          <UIButton
            checked={filtersData?.office_id !== null}
            label={'Studio'}
            type={'filter'}
            onClick={() => {
              setOfficeMode(true);
            }}
          />
        </Box>
      </Box>
      <Box>
        <UIButton
          type={'button'}
          variant={'phone'}
          label={'Aggiungi paziente'}
          sxCustom={{
            whiteSpace: 'nowrap',
            height: '45px',
            textTransform: 'none'
          }}
          onClick={() => setAddPatientMode(true)}
        />
      </Box>
    </Box>
  );

  return (
    <Box className="products-view" w={'100%'} h={'100%'} overflow={'auto'}>
      <ModalUserPicsDoctor modalOpen={picsMode} handleModalClose={handleModalClose} user={selectedUser} />
      <ModalUserDetails modalOpen={viewMode} handleModalClose={handleModalClose} userRow={selectedUser} />
      <ModalAddPatient modalOpen={addPatientMode} handleModalClose={() => setAddPatientMode(false)} />

      <>
        <UiDialog
          type={'phone'}
          title={`${selectedUser?.name} ${selectedUser?.surname}`}
          subtitle={`${selectedUser?.phone_country?.dial_code} ${selectedUser?.phone}`}
          open={phoneMode}
          labelConfirm={
            <>
              <IconPhone stroke={'1.64'} size={'20px'} />
              <UITypography
                variant={'Oxygen700'}
                title={'Chiama'}
                size={'14px'}
                sxCustom={{
                  ml: '5px',
                  color: '#344054'
                }}
              />
            </>
          }
          onClose={() => setPhoneMode(false)}
          onConfirm={() => {
            window.open(`tel:${selectedUser?.phone_country?.dial_code}${selectedUser?.phone}`, '_blank');
          }}
          onSecondary={() => {
            navigator.clipboard.writeText(`${selectedUser?.phone_country?.dial_code} ${selectedUser?.phone}`);
            toast('Numero copiato negli appunti', {
              style: {
                fontSize: '14px',
                backgroundColor: '#00e676',
                color: '#ffffff'
              }
            });
            setSelectedAppointment(null);
            setPhoneMode(false);
          }}
        />

        <UiDialog
          type={'checkbox'}
          open={treatmentsMode}
          onClose={() => setTreatmentsMode(false)}
          title={'Seleziona trattamenti'}
          onConfirm={(value) => {
            setFiltersData({
              ...filtersData,
              treatment_ids: value
            });
            setTreatmentsMode(false);
          }}
          labelConfirm={'Applica'}
          secondaryButton={true}
          values={treatments?.map((treatment) => {
            return {
              label: treatment.name,
              value: treatment.id,
              checked: filtersData.treatment_ids.includes(treatment.id)
            };
          })}
          onSecondary={() => {
            setFiltersData({
              ...filtersData,
              treatment_ids: []
            });
            setTreatmentsMode(false);
          }}
        />

        {/*** OFFICE FILTER DIALOG ***/}
        <UiDialog
          type={'radio'}
          open={officeMode}
          onClose={() => setOfficeMode(false)}
          title={'Seleziona ufficio'}
          onConfirm={(value) => {
            setFiltersData({
              ...filtersData,
              office_id: value
            });
            setOfficeMode(false);
          }}
          labelConfirm={'Applica'}
          secondaryButton={true}
          values={offices?.map((office) => {
            return {
              label: office.name,
              value: office.id,
              checked: filtersData.office_id === office.id
            };
          })}
          onSecondary={() => {
            setFiltersData({
              ...filtersData,
              office_id: null
            });
            setOfficeMode(false);
          }}
        />
      </>

      <UIPageHeader
        title={'Pazienti'}
        subtitle={
          "Gestisci qui i tuoi pazienti. In questa lista appariranno i pazienti che hanno prenotato almeno un appuntamento con te. E' possibile filtrare i pazienti per trattamento e studio. I pazienti che non hanno completato l'onboarding non saranno mostrati."
        }
      />

      <SectionHeader title={'Paziente'} />
      <UITable
        columns={[
          {
            name: 'Paziente',
            value: 'patientEl',
            size: 120
          },
          {
            name: 'OnBoarding Completato',
            value: 'onBoardingEl',
            size: 100
          },
          {
            name: '',
            value: 'actionsEl',
            size: 80
          }
        ]}
        prepareValues={prepareValues}
        enableSearch={true}
        searchSubtitle={`E' possibile cercare per nome, cognome, email o numero di cellulare`}
        enableCustomFilters={true}
        filtersData={filtersData}
        filtersButtons={filtersButtons}
        tableHeight={'calc(100vh - 400px)'}
        forceUpdate={forceUpdate}
        setForceUpdate={setForceUpdate}
        infiniteScrollEndMessage={'Non ci sono altri utenti da mostrare'}
        infiniteScrollFunction={onListUsersAdminPaginated}
      />
    </Box>
  );
};
export default UsersView;
