import React, { useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';
import './custom-products.css';
import { IconEdit, IconPlus } from '@tabler/icons-react';
import ModalEdit from './modalEdit';
import { useShop } from '../../../hooks/Shop/useShop';
import UITable from '../../utilities/Table';
import { SectionHeader, UIPageHeader } from '../../utilities/Headers';

const ShopDiscounts = () => {
  const [selectedDiscount, setselectedDiscount] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);

  const { onListShopDiscounts } = useShop();

  const handleModalClose = useCallback(() => {
    setEditMode(false);
    setForceUpdate(true);
  }, []);

  const prepareValues = (discounts) =>
    discounts?.map((discount) => {
      return {
        discount: discount,
        titleEl: (
          <Box>
            <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
              {discount?.title}
            </Typography>
          </Box>
        ),
        actionsEl: (
          <Box
            onClick={() => {
              setselectedDiscount(discount);
              setEditMode(true);
            }}
          >
            <IconEdit cursor={'pointer'} />
          </Box>
        )
      };
    });

  return (
    <Box className="products-view" w={'100%'} h={'100%'} overflow={'auto'}>
      <Box
        position={'fixed'}
        display={'flex'}
        right={10}
        bottom={10}
        bgcolor={'#2c3e50'}
        p={'10px'}
        borderRadius={'100%'}
        w={'50px'}
        h={'50px'}
        color={'white'}
        justifyContent={'center'}
        alignItems={'center'}
        cursor={'pointer'}
        onClick={() => {
          setselectedDiscount(null);
          setEditMode(true);
        }}
      >
        <IconPlus stroke={3} size="2rem" />
      </Box>

      <ModalEdit modalOpen={editMode} handleModalClose={handleModalClose} discount={selectedDiscount} />

      <UIPageHeader
        title={'Sconti'}
        subtitle={
          'Gestisci qui i tuoi sconti. In questa lista appariranno tutti gli sconti che hai creato. Clicca sull\'icona dell\'editor per modificarli.'
        }
      />

      <SectionHeader title={'Ricerca sconto'} subtitle={`E' possibile cercare per titolo.`} />
      <UITable
        columns={[
          {
            name: 'Titolo',
            value: 'titleEl',
            size: 400
          },
          {
            name: 'Azioni',
            value: 'actionsEl',
            size: 100
          }
        ]}
        prepareValues={prepareValues}
        enableSearch={true}
        enableCustomFilters={false}
        infiniteScrollEndMessage={'Non ci sono altri articoli da mostrare'}
        infiniteScrollFunction={onListShopDiscounts}
        tableHeight={'calc(100vh - 400px)'}
        forceUpdate={forceUpdate}
        setForceUpdate={setForceUpdate}
      />
    </Box>
  );
};
export default ShopDiscounts;
