import React from 'react';
import { Box } from '@chakra-ui/react';
import UITypography from './Typography';

export const UIPageHeader = ({ title, subtitle = null, titleSize = '20px', subtitleSize = '13px', sx = {}, sxSubtitle = {} }) => (
  <Box className={'page-header'} m={'30px 10px 5px 10px'} sx={sx}>
    <UITypography
      variant={'Oxygen700'}
      size={titleSize}
      title={title}
      sxCustom={{
        ...sx
      }}
    />

    {subtitle && (
      <UITypography
        variant={'Oxygen300'}
        size={subtitleSize}
        title={subtitle}
        sxCustom={{
          my: 2,
          lineHeight: '15px',
          ...sxSubtitle
        }}
      />
    )}
  </Box>
);

export const UIPageTitle = ({ title, titleSize = '18px', sx = {} }) => (
  <Box className={'page-title'} m={'30px 10px 5px 10px'} sx={sx}>
    <UITypography
      variant={'Oxygen700'}
      size={titleSize}
      title={title}
      sxCustom={{
        ...sx
      }}
    />
  </Box>
);

export const SectionHeader = ({ title, subtitle = null, titleSize = '15px', subtitleSize = '12px', sx = {} }) => (
  <Box className={'section-header'} m={'20px 10px 10px 10px'} sx={sx}>
    <UITypography variant={'Oxygen700'} size={titleSize} title={title} />
    {subtitle && (
      <UITypography
        variant={'Oxygen300'}
        size={subtitleSize}
        title={subtitle}
        sxCustom={{
          my: 1,
          lineHeight: '15px'
        }}
      />
    )}
  </Box>
);

export const UIFormCellHeader = ({ title, titleSize = '13px', sx = {} }) => (
  <Box className={'section-header'} m={'5px'} sx={sx}>
    <UITypography
      variant={'Oxygen700'}
      size={titleSize}
      title={title}
      sxCustom={{
        color: '#667085',
        ...sx
      }}
    />
  </Box>
);
