import { Box, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import moment from 'moment/moment';
import { registerLocale } from 'react-datepicker';
import it from 'date-fns/locale/it';
import { useCalendar } from '../../../hooks/Calendar/useCalendar';
import { SectionHeader, UIPageTitle } from '../../utilities/Headers';
import { UIModal, UIModalBody, UIModalButtons, UiModalInternal } from '../../utilities/Modal';
import { UiDialog } from '../../utilities/Dialog';
import { UIFormControlBox } from '../../utilities/Form';
import { UIInputSelect } from '../../utilities/InputSelect';
import { UIButton } from '../../utilities/Button';
import { IconCheck, IconPlus, IconTrash } from '@tabler/icons-react';

registerLocale('it', it);

const ModalTimeEdit = ({ modalOpen, handleModalClose, calendar_day, interval }) => {
  let [deleteMode, setDeleteMode] = useState(false);
  let [intervalData, setIntervalData] = useState(null);
  let [intervals, setIntervals] = useState([]);

  const { onUpdateTimeDoctor, onCreateTimeDoctor, onDeleteTimeDoctor } = useCalendar();

  useEffect(() => {
    if (interval) {
      setIntervalData(interval[0]);
      setIntervals(
        interval?.map((interval) => {
          return {
            id: interval.id,
            start_hour: interval.start_hour,
            end_hour: interval.end_hour
          };
        })
      );
    }
  }, [interval]);

  const handleInternalClose = () => {
    setDeleteMode(false);
    setIntervals([]);
    setIntervalData(null);
    handleModalClose();
  };

  const handleSubmit = async (values) => {
    const { repeat_every, start_date, end_date } = values;

    if (intervalData) {
      onUpdateTimeDoctor({
        time_group_uuid: intervalData?.group_uuid,
        time_data: {
          calendar_day_id: calendar_day.id,
          intervals,
          start_date: moment(start_date).format('YYYY-MM-DD'),
          end_date: end_date ? moment(end_date).format('YYYY-MM-DD') : null,
          repeat_every
        }
      }).then((res) => {
        if (res?.response) {
          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          handleInternalClose();
        }
      });
    } else {
      onCreateTimeDoctor({
        time_data: {
          calendar_day_id: calendar_day.id,
          intervals,
          start_date: moment(start_date).format('YYYY-MM-DD'),
          end_date: end_date ? moment(end_date).format('YYYY-MM-DD') : undefined,
          repeat_every
        }
      }).then((res) => {
        if (res?.response) {
          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          handleInternalClose();
        }
      });
    }
  };

  const handleDeleteSingleInterval = async (index) => {
    intervals.splice(index, 1);
    setIntervals([...intervals]);
  };

  const handleUpdateInterval = async (index, key, value) => {
    intervals[index][key] = moment(value).format('HH:mm');
    setIntervals([...intervals]);
  };

  const handleDeleteInterval = async () => {
    await onDeleteTimeDoctor({
      group_uuid: intervalData.group_uuid
    });
    setDeleteMode(false);
    handleModalClose();
  };

  return (
    <UIModal open={modalOpen} onClose={handleInternalClose} allowFullScreen={true} className={'modal'}>
      <UiDialog
        type={'default'}
        variant={'alert'}
        title={'Vuoi eliminare questo intervallo?'}
        open={deleteMode}
        onClose={() => setDeleteMode(false)}
        onConfirm={handleDeleteInterval}
      />

      <UiModalInternal onClose={handleInternalClose} isLoading={interval && intervalData === null}>
        <Formik
          initialValues={{
            repeat_every: intervalData?.repeat_every ?? null,
            start_date: intervalData?.start_date ? moment(intervalData?.start_date) : moment(),
            end_date: intervalData?.end_date ? moment(intervalData?.end_date) : null
          }}
          validationSchema={Yup.object().shape({
            repeat_every: Yup.number().required('Ripetizione richiesta'),
            start_date: Yup.string().required('Data di inizio richiesta'),
            end_date: Yup.string().nullable()
          })}
          onSubmit={(values) => handleSubmit(values)}
          enableReinitialize={true}
        >
          {({ errors, handleBlur, handleSubmit, touched, values, setFieldValue }) => (
            <form onSubmit={handleSubmit} id={'appointment-form'}>
              <UIModalBody
                sx={{
                  bgcolor: 'transparent'
                }}
              >
                <UIPageTitle title={'Imposta orario'} />
                <SectionHeader
                  title={'Fascia oraria'}
                  subtitle={
                    'Inserisci le fasce orarie lavorative della giornata. E’ necessario avere almeno una fascia oraria perché il giorno sia valido, due fasce orarie possono esserti utili, ad esempio, per definire i tempi della pausa pranzo.'
                  }
                />

                <UIFormControlBox
                  sx={{
                    mb: 2,
                    p: 0
                  }}
                  error={Boolean(intervals.length === 0)}
                  error_message={'Inserisci almeno un intervallo'}
                >
                  {intervals?.map((interval, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                        gap: '10px',
                        px: '10px',
                        mb: '10px'
                      }}
                    >
                      <UIFormControlBox
                        label={'Orario di inizio'}
                        sx={{
                          p: 0
                        }}
                      >
                        <TimePicker
                          value={moment(interval?.start_hour, 'HH:mm')}
                          format={'HH:mm'}
                          onChange={(res) => {
                            handleUpdateInterval(index, 'start_hour', res);
                          }}
                          ampm={false}
                          minutesStep={5}
                          minTime={moment('00:00', 'HH:mm')}
                          maxTime={moment('23:30', 'HH:mm')}
                        />
                      </UIFormControlBox>
                      <UIFormControlBox
                        label={'Orario di fine'}
                        sx={{
                          p: 0
                        }}
                      >
                        <TimePicker
                          value={moment(interval?.end_hour, 'HH:mm')}
                          format={'HH:mm'}
                          onChange={(res) => {
                            handleUpdateInterval(index, 'end_hour', res);
                          }}
                          ampm={false}
                          minutesStep={5}
                          minTime={moment('00:00', 'HH:mm')}
                          maxTime={moment('23:30', 'HH:mm')}
                        />
                      </UIFormControlBox>
                      <Box>
                        <UIButton
                          type={'submit'}
                          variant={'cancel'}
                          onClick={() => handleDeleteSingleInterval(index)}
                          icon={<IconTrash stroke={1.64} size={'25px'} />}
                          sxCustom={{
                            width: '50px',
                            bgcolor: '#FF646C'
                          }}
                        />
                      </Box>
                    </Box>
                  ))}
                </UIFormControlBox>

                <Box
                  sx={{
                    width: '65px',
                    height: '30px',
                    m: '15px auto'
                  }}
                >
                  <UIButton
                    variant={'copy'}
                    onClick={() => {
                      setIntervals([
                        ...intervals,
                        {
                          start_hour: '00:00',
                          end_hour: '23:30'
                        }
                      ]);
                    }}
                    icon={<IconPlus stroke={2} size={'20px'} />}
                    sxCustom={{
                      width: '100%',
                      bgcolor: '#FECE2E',
                      color: '#FFF',
                      boxShadow: '0px 2px 4px 0px #00000040'
                    }}
                  />
                </Box>

                <Divider
                  sx={{
                    border: '1px solid #E3E3E3'
                  }}
                />
                <SectionHeader title={'Ripetizione'} subtitle={'Seleziona il periodo di validità della fascia oraria.'} />

                <UIFormControlBox label={'Ripeti ogni'} error={Boolean(touched.repeat_every && errors.repeat_every)}>
                  <UIInputSelect
                    name={'repeat_every'}
                    fullWidth={true}
                    value={values?.repeat_every}
                    onChange={(e) => {
                      setFieldValue('repeat_every', e.target.value);
                    }}
                    options={[
                      {
                        value: 1,
                        label: '1 settimana'
                      },
                      {
                        value: 2,
                        label: '2 settimane'
                      },
                      {
                        value: 3,
                        label: '3 settimane'
                      },
                      {
                        value: 4,
                        label: '4 settimane'
                      }
                    ]}
                  />
                </UIFormControlBox>

                <UIFormControlBox label={'Data di inizio intervallo'} error={Boolean(touched.start_date && errors.start_date)}>
                  <DatePicker
                    value={values?.start_date}
                    dateFormat="dd/MM/yyyy"
                    onChange={(res) => {
                      setFieldValue('start_date', res);
                    }}
                    locale={'it'}
                    style={{ width: '100%', border: '1px solid red' }}
                    onBlur={handleBlur}
                  />
                </UIFormControlBox>

                <UIFormControlBox
                  label={'Data di fine intervallo'}
                  subtitle={'* Lasciare vuoto questo campo se l’intervallo ha durata infinita'}
                  error={Boolean(touched.end_date && errors.end_date)}
                >
                  <DatePicker
                    value={values?.end_date}
                    dateFormat="dd/MM/yyyy"
                    label=""
                    onChange={(res) => {
                      setFieldValue('end_date', res);
                    }}
                    disablePast={true}
                    locale={'it'}
                    style={{ width: '100%', border: '1px solid red' }}
                    onBlur={handleBlur}
                  />
                </UIFormControlBox>
              </UIModalBody>
              <UIModalButtons>
                <UIButton
                  type={'submit'}
                  onClick={() => setDeleteMode(true)}
                  icon={<IconTrash stroke={1.64} size="25px" />}
                  sxCustom={{
                    bgcolor: '#FF646C'
                  }}
                />
                <UIButton type={'submit'} onClick={handleSubmit} icon={<IconCheck stroke={'1.64'} size={'26px'} />} />
              </UIModalButtons>
            </form>
          )}
        </Formik>
      </UiModalInternal>
    </UIModal>
  );
};
export default ModalTimeEdit;
