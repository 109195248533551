import {
  LIST_SHOP_PRODUCTS_BY_UUIDS,
  LIST_SHOP_PRODUCTS_BY_QUERY,
  CREATE_SHOP_CATEGORY,
  UPDATE_SHOP_CATEGORY,
  DELETE_SHOP_CATEGORY,
  LIST_SHOP_CATEGORIES,
  GET_SHOP_CATEGORY,
  GET_SHOP_PRODUCT,
  CREATE_SHOP_PRODUCT,
  UPDATE_SHOP_PRODUCT,
  DELETE_SHOP_PRODUCT,
  UPLOAD_SHOP_PRODUCT_MEDIA,
  GET_SHOP_DISCOUNT,
  LIST_SHOP_DISCOUNTS,
  CREATE_SHOP_DISCOUNT,
  UPDATE_SHOP_DISCOUNT,
  DELETE_SHOP_DISCOUNT,
  LIST_PRODUCTS_REVIEWS,
  GET_SHOP_PRODUCT_REVIEW,
  UPDATE_SHOP_REVIEW,
  SET_SHOP_HIDDEN_REVIEW,
  SET_SHOP_REVIEW_REPORTS_IGNORED,
  LIST_SHOP_BANNERS,
  GET_SHOP_BANNER,
  CREATE_SHOP_BANNER,
  UPDATE_SHOP_BANNER,
  DELETE_SHOP_BANNER,
  LIST_SHOP_BANNERS_BACKGROUNDS,
  UPLOAD_SHOP_BANNER_IMAGE,
  UPDATE_SHOP_ORDER_STATUS_ADMIN,
  LIST_SHOP_ORDERS_ADMIN,
  GET_SHOP_ORDER_ADMIN,
  UPDATE_SHOP_ORDER_ADMIN,
  LIST_SHOP_COUPONS_ADMIN,
  GET_SHOP_COUPON_ADMIN,
  CREATE_SHOP_COUPON_ADMIN,
  UPDATE_SHOP_COUPON_ADMIN,
  DELETE_SHOP_COUPON_ADMIN,
  COUNT_SHOP_ORDERS_ADMIN,
  UPLOAD_SHOP_ORDER_INVOICE,
  DELETE_SHOP_ORDER_ADMIN,
  LIST_SHOP_WAREHOUSE_PAGINATED,
  GET_COMMISSIONS_DATA,
  LIST_SHOP_MONTHLY_COMMISSIONS,
  GET_SUGGESTED_USERS,
  GET_COMMISSION,
  GET_COMMISSION_ITEMS,
  UPDATE_SHOP_PRODUCT_VARIANT_QUANTITY,
  LIST_SHOP_MONTHLY_COMMISSIONS_ADMIN,
  SET_COMMISSION_PAYED, LIST_SHOP_PRODUCTS_ADMIN_PAGINATED
} from './graphql/queries';
import { useQuery } from '../useQuery';
import { useMutation } from '../useMutation';

/** @format */
export const useShop = () => {
  //! SHOP PRODUCTS
  const { getDataGQL: listShopProductsAdminPaginated } = useQuery(LIST_SHOP_PRODUCTS_ADMIN_PAGINATED);
  const { getDataGQL: listShopProductsByQuery } = useQuery(LIST_SHOP_PRODUCTS_BY_QUERY);
  const { getDataGQL: listShopWarehousePaginated } = useQuery(LIST_SHOP_WAREHOUSE_PAGINATED);
  const { getDataGQL: listShopProductsByUuids } = useQuery(LIST_SHOP_PRODUCTS_BY_UUIDS);
  const { getDataGQL: getShopProductAdmin } = useQuery(GET_SHOP_PRODUCT);
  const { getDataGQL: createShopProduct } = useQuery(CREATE_SHOP_PRODUCT);
  const { getDataGQL: updateShopProduct } = useQuery(UPDATE_SHOP_PRODUCT);
  const { getDataGQL: uploadShopProductMedia } = useQuery(UPLOAD_SHOP_PRODUCT_MEDIA);
  const { getDataGQL: deleteShopProduct } = useQuery(DELETE_SHOP_PRODUCT);
  const { getDataGQL: updateShopProductVariantQuantity } = useQuery(UPDATE_SHOP_PRODUCT_VARIANT_QUANTITY);

  //! SHOP CATEGORIES
  const { getDataGQL: listShopCategories } = useQuery(LIST_SHOP_CATEGORIES);
  const { getDataGQL: getShopCategory } = useQuery(GET_SHOP_CATEGORY);
  const { getDataGQL: createShopCategory } = useQuery(CREATE_SHOP_CATEGORY);
  const { getDataGQL: updateShopCategory } = useQuery(UPDATE_SHOP_CATEGORY);
  const { getDataGQL: deleteShopCategory } = useQuery(DELETE_SHOP_CATEGORY);

  //! SHOP DISCOUNTS
  const { getDataGQL: listShopDiscounts } = useQuery(LIST_SHOP_DISCOUNTS);
  const { getDataGQL: getShopDiscount } = useQuery(GET_SHOP_DISCOUNT);
  const { getDataGQL: createShopDiscount } = useQuery(CREATE_SHOP_DISCOUNT);
  const { getDataGQL: updateShopDiscount } = useQuery(UPDATE_SHOP_DISCOUNT);
  const { getDataGQL: deleteShopDiscount } = useQuery(DELETE_SHOP_DISCOUNT);

  //! SHOP BANNERS
  const { getDataGQL: listShopBanners } = useQuery(LIST_SHOP_BANNERS);
  const { getDataGQL: listShopBannersBackgrounds } = useQuery(LIST_SHOP_BANNERS_BACKGROUNDS);
  const { getDataGQL: getShopBanner } = useQuery(GET_SHOP_BANNER);
  const { getDataGQL: createShopBanner } = useQuery(CREATE_SHOP_BANNER);
  const { getDataGQL: updateShopBanner } = useQuery(UPDATE_SHOP_BANNER);
  const { getDataGQL: uploadShopBannerImage } = useQuery(UPLOAD_SHOP_BANNER_IMAGE);
  const { getDataGQL: deleteShopBanner } = useQuery(DELETE_SHOP_BANNER);

  //! PRODUCTS REVIEW
  const { getDataGQL: listShopProductsReviews } = useQuery(LIST_PRODUCTS_REVIEWS);
  const { getDataGQL: getShopProductReview } = useQuery(GET_SHOP_PRODUCT_REVIEW);
  const { getDataGQL: setShopIgnoredReviewReports } = useMutation(SET_SHOP_REVIEW_REPORTS_IGNORED);
  const { getDataGQL: setShopHiddenReview } = useMutation(SET_SHOP_HIDDEN_REVIEW);
  const { getDataGQL: updateShopReview } = useMutation(UPDATE_SHOP_REVIEW);

  //! SHOP ORDERS
  const { getDataGQL: countShopOrdersAdmin } = useQuery(COUNT_SHOP_ORDERS_ADMIN);
  const { getDataGQL: listShopOrdersAdmin } = useQuery(LIST_SHOP_ORDERS_ADMIN);
  const { getDataGQL: getShopOrderAdmin } = useQuery(GET_SHOP_ORDER_ADMIN);
  const { getDataGQL: updateShopOrderStatusAdmin } = useMutation(UPDATE_SHOP_ORDER_STATUS_ADMIN);
  const { getDataGQL: updateShopOrderAdmin } = useMutation(UPDATE_SHOP_ORDER_ADMIN);
  const { getDataGQL: uploadShopOrderInvoice } = useMutation(UPLOAD_SHOP_ORDER_INVOICE);
  const { getDataGQL: deleteShopOrderAdmin } = useMutation(DELETE_SHOP_ORDER_ADMIN);

  //! SHOP COUPONS
  const { getDataGQL: listShopCouponsAdmin } = useQuery(LIST_SHOP_COUPONS_ADMIN);
  const { getDataGQL: getShopCouponAdmin } = useQuery(GET_SHOP_COUPON_ADMIN);
  const { getDataGQL: createShopCouponAdmin } = useMutation(CREATE_SHOP_COUPON_ADMIN);
  const { getDataGQL: updateShopCouponAdmin } = useMutation(UPDATE_SHOP_COUPON_ADMIN);
  const { getDataGQL: deleteShopCouponAdmin } = useMutation(DELETE_SHOP_COUPON_ADMIN);

  //! SHOP COMMISSIONS
  const { getDataGQL: getCommissionsData } = useQuery(GET_COMMISSIONS_DATA);
  const { getDataGQL: listShopMonthlyCommissions } = useQuery(LIST_SHOP_MONTHLY_COMMISSIONS);
  const { getDataGQL: listShopMonthlyCommissionsAdmin } = useQuery(LIST_SHOP_MONTHLY_COMMISSIONS_ADMIN);
  const { getDataGQL: getSuggestedUsers } = useQuery(GET_SUGGESTED_USERS);
  const { getDataGQL: getCommission } = useQuery(GET_COMMISSION);
  const { getDataGQL: getCommissionItems } = useQuery(GET_COMMISSION_ITEMS);
  const { getDataGQL: setCommissionPayed } = useQuery(SET_COMMISSION_PAYED);

  //! SHOP PRODUCTS

  const onListShopProductsAdminPaginated = async ({ filters = {}} = {}) => {
    const response = await listShopProductsAdminPaginated({
      variables: {
        filters,
      }
    });
    if (response?.adminRoute?.listShopProductsAdminPaginated) {
      return response?.adminRoute?.listShopProductsAdminPaginated;
    }
    return null;
  }

  const onListShopProductsByQuery = async ({ query = '', onlyActive = false, forceAll = false } = {}) => {
    const response = await listShopProductsByQuery({
      variables: {
        query,
        onlyActive,
        forceAll
      }
    });
    if (response?.adminRoute?.listShopProductsByQuery) {
      return response?.adminRoute?.listShopProductsByQuery;
    }
    return null;
  };

  const onListShopWarehousePaginated = async ({ filters = {}, query = '', status = null } = {}) => {
    const response = await listShopWarehousePaginated({
      variables: {
        filters,
        query,
        status
      }
    });
    if (response?.adminRoute?.listShopWarehousePaginated) {
      return response?.adminRoute?.listShopWarehousePaginated;
    }
    return null;
  };

  const onListShopProductsByUuids = async ({ products_uuids }) => {
    const response = await listShopProductsByUuids({
      variables: {
        products_uuids
      }
    });
    if (response?.adminRoute?.listShopProductsByUuids) {
      return response?.adminRoute?.listShopProductsByUuids;
    }
    return null;
  };

  const onGetShopProductAdmin = async ({ product_uuid }) => {
    const response = await getShopProductAdmin({
      variables: {
        product_uuid
      }
    });
    if (response?.adminRoute?.getShopProductAdmin) {
      return response?.adminRoute?.getShopProductAdmin;
    }
    return null;
  };

  const onCreateShopProduct = async ({ product_data }) => {
    const response = await createShopProduct({
      variables: {
        product_data
      }
    });
    if (response?.adminRoute?.createShopProduct) {
      return response?.adminRoute?.createShopProduct;
    }
    return null;
  };

  const onUpdateShopProduct = async ({ product_uuid, product_data }) => {
    const response = await updateShopProduct({
      variables: {
        product_uuid,
        product_data
      }
    });
    if (response?.adminRoute?.updateShopProduct) {
      return response?.adminRoute?.updateShopProduct;
    }
    return null;
  };

  const onUpdateShopProductVariantQuantity = async ({ variant_uuid, quantity }) => {
    const response = await updateShopProductVariantQuantity({
      variables: {
        variant_uuid,
        quantity
      }
    });
    if (response?.adminRoute?.updateShopProductVariantQuantity) {
      return response?.adminRoute?.updateShopProductVariantQuantity;
    }
    return null;
  };

  const onUploadShopProductMedia = async ({ product_uuid, filename, contentType }) => {
    const response = await uploadShopProductMedia({
      variables: {
        product_uuid,
        filename,
        contentType
      }
    });
    if (response?.adminRoute?.uploadShopProductMedia) {
      return response?.adminRoute?.uploadShopProductMedia;
    }
    return null;
  };

  const onDeleteShopProduct = async ({ product_uuid }) => {
    const response = await deleteShopProduct({
      variables: {
        product_uuid
      }
    });
    if (response?.adminRoute?.deleteShopProduct) {
      return response?.adminRoute?.deleteShopProduct;
    }
    return null;
  };

  //! SHOP CATEGORIES

  const onListShopCategories = async ({ type = null } = {}) => {
    const response = await listShopCategories({
      variables: {
        type
      }
    });
    if (response?.adminRoute?.listShopCategories) {
      return response?.adminRoute?.listShopCategories;
    }
    return null;
  };

  const onGetShopCategory = async ({ category_uuid }) => {
    const response = await getShopCategory({
      variables: {
        category_uuid
      }
    });
    if (response?.adminRoute?.getShopCategory) {
      return response?.adminRoute?.getShopCategory;
    }
    return null;
  };

  const onCreateShopCategory = async ({ category_data }) => {
    const response = await createShopCategory({
      variables: {
        category_data
      }
    });
    if (response?.adminRoute?.createShopCategory) {
      return response?.adminRoute?.createShopCategory;
    }
    return null;
  };

  const onUpdateShopCategory = async ({ category_uuid, category_data }) => {
    const response = await updateShopCategory({
      variables: {
        category_uuid,
        category_data
      }
    });
    if (response?.adminRoute?.updateShopCategory) {
      return response?.adminRoute?.updateShopCategory;
    }
    return null;
  };

  const onDeleteShopCategory = async ({ category_uuid }) => {
    const response = await deleteShopCategory({
      variables: {
        category_uuid
      }
    });
    if (response?.adminRoute?.deleteShopCategory) {
      return response?.adminRoute?.deleteShopCategory;
    }
    return null;
  };

  //! SHOP DISCOUNTS

  const onListShopDiscounts = async ({filters = {}} = {}) => {
    const response = await listShopDiscounts({
      variables: {
        filters
      }
    });
    if (response?.adminRoute?.listShopDiscounts) {
      return response?.adminRoute?.listShopDiscounts;
    }
    return null;
  };

  const onGetShopDiscount = async ({ discount_uuid }) => {
    const response = await getShopDiscount({
      variables: {
        discount_uuid
      }
    });
    if (response?.adminRoute?.getShopDiscount) {
      return response?.adminRoute?.getShopDiscount;
    }
    return null;
  };

  const onCreateShopDiscount = async ({ discount_data }) => {
    const response = await createShopDiscount({
      variables: {
        discount_data
      }
    });
    if (response?.adminRoute?.createShopDiscount) {
      return response?.adminRoute?.createShopDiscount;
    }
    return null;
  };

  const onUpdateShopDiscount = async ({ discount_uuid, discount_data }) => {
    const response = await updateShopDiscount({
      variables: {
        discount_uuid,
        discount_data
      }
    });
    if (response?.adminRoute?.updateShopDiscount) {
      return response?.adminRoute?.updateShopDiscount;
    }
    return null;
  };

  const onDeleteShopDiscount = async ({ discount_uuid }) => {
    const response = await deleteShopDiscount({
      variables: {
        discount_uuid
      }
    });
    if (response?.adminRoute?.deleteShopDiscount) {
      return response?.adminRoute?.deleteShopDiscount;
    }
    return null;
  };

  //! SHOP BANNERS

  const onListShopBanners = async ({filters = {}} = {}) => {
    const response = await listShopBanners({
      variables: {
        filters
      }
    });
    if (response?.adminRoute?.listShopBanners) {
      return response?.adminRoute?.listShopBanners;
    }
    return null;
  };

  const onListShopBannersBackgrounds = async () => {
    const response = await listShopBannersBackgrounds();
    if (response?.adminRoute?.listShopBannersBackgrounds) {
      return response?.adminRoute?.listShopBannersBackgrounds;
    }
    return null;
  };

  const onGetShopBanner = async ({ banner_uuid }) => {
    const response = await getShopBanner({
      variables: {
        banner_uuid
      }
    });
    if (response?.adminRoute?.getShopBanner) {
      return response?.adminRoute?.getShopBanner;
    }
    return null;
  };

  const onCreateShopBanner = async ({ banner_data }) => {
    const response = await createShopBanner({
      variables: {
        banner_data
      }
    });
    if (response?.adminRoute?.createShopBanner) {
      return response?.adminRoute?.createShopBanner;
    }
    return null;
  };

  const onUpdateShopBanner = async ({ banner_uuid, banner_data }) => {
    const response = await updateShopBanner({
      variables: {
        banner_uuid,
        banner_data
      }
    });
    if (response?.adminRoute?.updateShopBanner) {
      return response?.adminRoute?.updateShopBanner;
    }
    return null;
  };

  const onUploadShopBannerImage = async ({ banner_uuid, contentType, filename, image_type = 'mobile' }) => {
    const response = await uploadShopBannerImage({
      variables: {
        banner_uuid,
        contentType,
        filename,
        image_type
      }
    });
    if (response?.adminRoute?.uploadShopBannerImage) {
      return response?.adminRoute?.uploadShopBannerImage;
    }
    return null;
  };

  const onDeleteShopBanner = async ({ banner_uuid }) => {
    const response = await deleteShopBanner({
      variables: {
        banner_uuid
      }
    });
    if (response?.adminRoute?.deleteShopBanner) {
      return response?.adminRoute?.deleteShopBanner;
    }
    return null;
  };

  //! SHOP REVIEWS

  const onListShopReviews = async ({ product_id, account_id, rating, limit = 20, page = 1 } = {}) => {
    const response = await listShopProductsReviews({
      variables: {
        product_id,
        account_id,
        rating,
        limit,
        page
      }
    });
    if (response?.adminRoute?.listShopProductsReviews) {
      return response?.adminRoute?.listShopProductsReviews;
    }
    return null;
  };

  const onGetShopProductReview = async ({ review_uuid }) => {
    const response = await getShopProductReview({
      variables: {
        review_uuid
      }
    });
    if (response?.adminRoute?.getShopProductReview) {
      return response?.adminRoute?.getShopProductReview;
    }
    return null;
  };

  const onSetShopIgnoredReviewReports = async ({ review_uuid }) => {
    const response = await setShopIgnoredReviewReports({
      variables: {
        review_uuid
      }
    });
    if (response?.adminRoute?.setShopIgnoredReviewReports) {
      return response?.adminRoute?.setShopIgnoredReviewReports;
    }
    return null;
  };

  const onSetShopHiddenReview = async ({ review_uuid }) => {
    const response = await setShopHiddenReview({
      variables: {
        review_uuid
      }
    });
    if (response?.adminRoute?.setShopHiddenReview) {
      return response?.adminRoute?.setShopHiddenReview;
    }
    return null;
  };

  const onUpdateReview = async ({ review_uuid, review_data }) => {
    const response = await updateShopReview({
      variables: {
        review_uuid,
        review_data
      }
    });
    if (response?.adminRoute?.updateShopReview) {
      return response?.adminRoute?.updateShopReview;
    }
    return null;
  };

  //! SHOP ORDERS

  const onCountShopOrdersAdmin = async ({ status = 'payed' } = {}) => {
    const response = await countShopOrdersAdmin({
      variables: {
        status
      }
    });
    if (response?.adminRoute?.countShopOrdersAdmin) {
      return response?.adminRoute?.countShopOrdersAdmin;
    }
    return null;
  };

  const onListShopOrdersAdmin = async ({ account_uuid, status, limit = 20, page = 1 } = {}) => {
    const response = await listShopOrdersAdmin({
      variables: {
        account_uuid,
        status,
        limit,
        page
      }
    });
    if (response?.adminRoute?.listShopOrdersAdmin) {
      return response?.adminRoute?.listShopOrdersAdmin;
    }
    return null;
  };

  const onGetShopOrderAdmin = async ({ order_uuid }) => {
    const response = await getShopOrderAdmin({
      variables: {
        order_uuid
      }
    });
    if (response?.adminRoute?.getShopOrderAdmin) {
      return response?.adminRoute?.getShopOrderAdmin;
    }
    return null;
  };

  const onUpdateShopOrderStatusAdmin = async ({ order_uuid, status }) => {
    const response = await updateShopOrderStatusAdmin({
      variables: {
        order_uuid,
        status
      }
    });
    if (response?.adminRoute?.updateShopOrderStatusAdmin) {
      return response?.adminRoute?.updateShopOrderStatusAdmin;
    }
    return null;
  };

  const onUpdateShopOrderAdmin = async ({ order_uuid, order_data }) => {
    const response = await updateShopOrderAdmin({
      variables: {
        order_uuid,
        order_data
      }
    });
    if (response?.adminRoute?.updateShopOrderAdmin) {
      return response?.adminRoute?.updateShopOrderAdmin;
    }
    return null;
  };

  const onUploadShopOrderInvoice = async ({ order_uuid, contentType, filename }) => {
    const response = await uploadShopOrderInvoice({
      variables: {
        order_uuid,
        contentType,
        filename
      }
    });
    if (response?.adminRoute?.uploadShopOrderInvoice) {
      return response?.adminRoute?.uploadShopOrderInvoice;
    }
    return null;
  };

  const onDeleteShopOrderAdmin = async ({ order_uuid }) => {
    const response = await deleteShopOrderAdmin({
      variables: {
        order_uuid
      }
    });
    if (response?.adminRoute?.deleteShopOrderAdmin) {
      return response?.adminRoute?.deleteShopOrderAdmin;
    }
    return null;
  };

  //! SHOP COUPONS

  const onListShopCouponsAdmin = async ({ limit = 20, page = 1, query = '' } = {}) => {
    const response = await listShopCouponsAdmin({
      variables: {
        limit,
        page,
        query
      }
    });
    if (response?.adminRoute?.listShopCouponsAdmin) {
      return response?.adminRoute?.listShopCouponsAdmin;
    }
    return null;
  };

  const onGetShopCouponAdmin = async ({ coupon_uuid }) => {
    const response = await getShopCouponAdmin({
      variables: {
        coupon_uuid
      }
    });
    if (response?.adminRoute?.getShopCouponAdmin) {
      return response?.adminRoute?.getShopCouponAdmin;
    }
    return null;
  };

  const onCreateShopCouponAdmin = async ({ coupon_data }) => {
    const response = await createShopCouponAdmin({
      variables: {
        coupon_data
      }
    });
    if (response?.adminRoute?.createShopCouponAdmin) {
      return response?.adminRoute?.createShopCouponAdmin;
    }
    return null;
  };

  const onUpdateShopCouponAdmin = async ({ coupon_uuid, coupon_data }) => {
    const response = await updateShopCouponAdmin({
      variables: {
        coupon_uuid,
        coupon_data
      }
    });
    if (response?.adminRoute?.updateShopCouponAdmin) {
      return response?.adminRoute?.updateShopCouponAdmin;
    }
    return null;
  };

  const onDeleteShopCouponAdmin = async ({ coupon_uuid }) => {
    const response = await deleteShopCouponAdmin({
      variables: {
        coupon_uuid
      }
    });
    if (response?.adminRoute?.deleteShopCouponAdmin) {
      return response?.adminRoute?.deleteShopCouponAdmin;
    }
    return null;
  };

  //! SHOP COMMISSIONS
  const onGetCommissionsData = async () => {
    const response = await getCommissionsData();
    if (response?.adminRoute?.getCommissionsData) {
      return response?.adminRoute?.getCommissionsData;
    }
    return null;
  };

  const onListShopMonthlyCommissions = async ({ filters = {} } = {}) => {
    const response = await listShopMonthlyCommissions({
      variables: {
        filters
      }
    });
    if (response?.adminRoute?.listShopMonthlyCommissions) {
      return response?.adminRoute?.listShopMonthlyCommissions;
    }
    return null;
  };

  const onListShopMonthlyCommissionsAdmin = async ({ filters = {} } = {}) => {
    const response = await listShopMonthlyCommissionsAdmin({
      variables: {
        filters
      }
    });
    if (response?.adminRoute?.listShopMonthlyCommissionsAdmin) {
      return response?.adminRoute?.listShopMonthlyCommissionsAdmin;
    }
    return null;
  };

  const onGetSuggestedUsers = async ({ filters = {} } = {}) => {
    const response = await getSuggestedUsers({
      variables: {
        filters
      }
    });
    if (response?.adminRoute?.getSuggestedUsers) {
      return response?.adminRoute?.getSuggestedUsers;
    }
    return null;
  };

  const onGetCommission = async ({ month } = {}) => {
    const response = await getCommission({
      variables: {
        month
      }
    });
    if (response?.adminRoute?.getCommission) {
      return response?.adminRoute?.getCommission;
    }
    return null;
  };

  const onGetCommissionItems = async ({ month } = {}) => {
    const response = await getCommissionItems({
      variables: {
        month
      }
    });
    if (response?.adminRoute?.getCommissionItems) {
      return response?.adminRoute?.getCommissionItems;
    }
    return null;
  };

  const onSetCommissionPayed = async ({ commission_uuid }) => {
    const response = await setCommissionPayed({
      variables: {
        commission_uuid
      }
    });
    if (response?.adminRoute?.setCommissionPayed) {
      return response?.adminRoute?.setCommissionPayed;
    }
    return null;
  };

  return {
    onListShopProductsAdminPaginated,
    onListShopProductsByQuery,
    onListShopWarehousePaginated,
    onListShopProductsByUuids,
    onGetShopProductAdmin,
    onCreateShopProduct,
    onUpdateShopProduct,
    onUpdateShopProductVariantQuantity,
    onUploadShopProductMedia,
    onDeleteShopProduct,
    onListShopCategories,
    onGetShopCategory,
    onCreateShopCategory,
    onUpdateShopCategory,
    onDeleteShopCategory,
    onListShopDiscounts,
    onGetShopDiscount,
    onCreateShopDiscount,
    onUpdateShopDiscount,
    onDeleteShopDiscount,
    onListShopReviews,
    onGetShopProductReview,
    onSetShopIgnoredReviewReports,
    onSetShopHiddenReview,
    onUpdateReview,
    onListShopBanners,
    onListShopBannersBackgrounds,
    onGetShopBanner,
    onCreateShopBanner,
    onUpdateShopBanner,
    onUploadShopBannerImage,
    onDeleteShopBanner,
    onCountShopOrdersAdmin,
    onListShopOrdersAdmin,
    onGetShopOrderAdmin,
    onUpdateShopOrderStatusAdmin,
    onUpdateShopOrderAdmin,
    onUploadShopOrderInvoice,
    onDeleteShopOrderAdmin,
    onListShopCouponsAdmin,
    onGetShopCouponAdmin,
    onCreateShopCouponAdmin,
    onUpdateShopCouponAdmin,
    onDeleteShopCouponAdmin,
    onGetCommissionsData,
    onListShopMonthlyCommissions,
    onListShopMonthlyCommissionsAdmin,
    onGetSuggestedUsers,
    onGetCommission,
    onGetCommissionItems,
    onSetCommissionPayed
  };
};
